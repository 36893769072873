import {
  BackupTable as BackupTableIcon,
  Check as CheckIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  QuestionMark as QuestionMarkIcon,
} from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import { LOADING_STATE } from '~/constants/LoadingState';

import {
  setDataExchanges_query,
  setDataExchanges_selectedCompany,
} from '~/redux/filtersSlice';

import DataExchangeService from '~/services/dataExchange.service';
import ExportService from '~/services/export.service';
import LocalStorageService from '~/services/localStorage.service';
import MapperService from '~/services/mapper.service';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

import { globalState } from '~/modules/applicationState';

import { sortByKey, updateByKey } from '~/utils/array';
import Log from '~/utils/logging';

import BasicForm from '~/components/BasicForm';
import { IconButton } from '~/components/IconButton';
import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '~/ui/atoms/Spinner';
import { SearchInput } from '~/ui/molecules/SearchInput';
import { SelectCompany } from '~/ui/molecules/SelectServerDriven';

import { DataExchangeTiles } from './DataExchangeTiles';

const mapStateToProps = (state) => ({
  dataExchanges: state.dataExchanges,
  query: state.filters.dataExchanges_query,
  selectedCompany: state.filters.dataExchanges_selectedCompany,
});
const mapDispatchToProps = () => ({
  setDataExchanges_query,
  setDataExchanges_selectedCompany,
});

class DataExchanges extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyChips: [],
      dataExchanges: [],
      filteredDataExchanges: [],
      requestInformationSelection: false,
      requestInformationSelectionList: [],
      supplierOverviewWarningAccepted: false,
      supplierOverviewWarningOpen: false,
    };
  }

  componentDidMount() {
    globalState.send({
      type: 'setPageTitle',
      pageTitle: 'Lieferantenübersicht',
    });

    DataExchangeService.loadDataExchanges();
    DataExchangeService.loadDataExchangeCompanies();

    this.loadData();

    this.setState({
      supplierOverviewWarningOpen:
        !LocalStorageService.getBooleanFromLocalStorage(
          LocalStorageService.SUPPLIER_OVERVIEW_WARNING_ACCEPTED,
          false,
        ),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.dataExchanges.dataExchanges) !==
        JSON.stringify(prevProps.dataExchanges.dataExchanges) ||
      JSON.stringify(this.props.dataExchanges.dataExchangeCompanies) !==
        JSON.stringify(prevProps.dataExchanges.dataExchangeCompanies) ||
      this.props.selectedCompany !== prevProps.selectedCompany
    ) {
      this.loadData();
    }

    if (
      this.props.query !== prevProps.query ||
      this.props.selectedCompany !== prevProps.selectedCompany ||
      JSON.stringify(this.state.dataExchanges) !==
        JSON.stringify(prevState.dataExchanges)
    ) {
      this.filterDataExchanges();
    }
  }

  loadData() {
    let dataExchanges = [];

    for (const dataExchange of this.props.dataExchanges.dataExchanges) {
      dataExchanges.push({
        activeCustomerIds: dataExchange.activeCustomerIds.join(', '),

        deliveryNoteStatus: dataExchange.exchangeDeliveryNotes
          ? DataExchangeCompany.STATUS.ACTIVATED.KEY
          : null,

        // Set alternative status via data exchange company later
        furtherInformation: dataExchange.furtherInformation,

        id: dataExchange.id,

        // Set alternative status via data exchange company later
        invoiceStatus: dataExchange.exchangeInvoices
          ? DataExchangeCompany.STATUS.ACTIVATED.KEY
          : null,

        knownIssues: dataExchange.knownIssues,

        overallStatus: DataExchangeCompany.STATUS.ACTIVATED.KEY,

        receiverId: dataExchange.receiverId,
        senderId: dataExchange.senderId,
      });
    }

    for (const dataExchangeCompany of this.props.dataExchanges
      .dataExchangeCompanies) {
      // If the data exchange company is blacklisted, we don't want to display it in the supplier overview.
      if (dataExchangeCompany.blacklisted) {
        continue;
      }

      const filteredDataExchanges = dataExchanges.filter(
        (dataExchange) => dataExchange.senderId === dataExchangeCompany.id,
      );

      // If there are data exchanges and the filter "Alle Firmen" is selected, the data exchanges are merged into one set.
      if (filteredDataExchanges.length > 0 && !this.props.selectedCompany) {
        const newDataExchange = filteredDataExchanges[0];

        newDataExchange.name = dataExchangeCompany.name;
        newDataExchange.address =
          dataExchangeCompany.address.getConcatenatedAddress();
        newDataExchange.rank = dataExchangeCompany.rank;
        // Only add the current issues for the data exchanges that have been "activated" for the specific company of the user
        newDataExchange.currentIssues = dataExchangeCompany.currentIssues;
        // Take the highest status during merge.
        newDataExchange.deliveryNoteStatus =
          DataExchangeCompany.getHighestStatus(
            filteredDataExchanges.map(
              (filteredDataExchange) => filteredDataExchange.deliveryNoteStatus,
            ),
          );
        newDataExchange.invoiceStatus = DataExchangeCompany.getHighestStatus(
          filteredDataExchanges.map(
            (filteredDataExchange) => filteredDataExchange.invoiceStatus,
          ),
        );
        // If no status set in any data exchange, set the status from the company as default.
        newDataExchange.deliveryNoteStatus ||=
          dataExchangeCompany.statusDeliveryNote;
        newDataExchange.invoiceStatus ||= dataExchangeCompany.statusInvoice;
        // Text fields should be appended during merge.
        newDataExchange.furtherInformation = filteredDataExchanges
          .map(
            (filteredDataExchange) => filteredDataExchange.furtherInformation,
          )
          .filter(Boolean)
          .join(', ');
        newDataExchange.activeCustomerIds = filteredDataExchanges
          .map((filteredDataExchange) => filteredDataExchange.activeCustomerIds)
          .filter(Boolean)
          .join(', ');
        newDataExchange.knownIssues = filteredDataExchanges
          .map((filteredDataExchange) => filteredDataExchange.knownIssues)
          .filter(Boolean)
          .join(', ');

        dataExchanges = updateByKey(
          dataExchanges,
          'id',
          newDataExchange.id,
          newDataExchange,
        );

        // Delete the other data exchanges as we only want to have the merged one.
        for (const [
          index,
          filteredDataExchange,
        ] of filteredDataExchanges.entries()) {
          if (index > 0) {
            dataExchanges = dataExchanges.filter(
              ({ id }) => id !== filteredDataExchange.id,
            );
          }
        }

        continue;
      }

      // If there are data exchanges and a specific company is selected as filter, each data exchange must be updated with the data exchange company information.
      // The filter on the company is applied in this.filterDataExchanges().
      if (filteredDataExchanges.length > 0 && this.props.selectedCompany) {
        for (const filteredDataExchange of filteredDataExchanges) {
          filteredDataExchange.name = dataExchangeCompany.name;
          filteredDataExchange.address =
            dataExchangeCompany.address.getConcatenatedAddress();
          filteredDataExchange.rank = dataExchangeCompany.rank;
          filteredDataExchange.deliveryNoteStatus ||=
            dataExchangeCompany.statusDeliveryNote;

          filteredDataExchange.invoiceStatus ||=
            dataExchangeCompany.statusInvoice;

          dataExchanges = updateByKey(
            dataExchanges,
            'id',
            filteredDataExchange.id,
            filteredDataExchange,
          );
        }

        continue;
      }

      // Last case: No data exchange for this data exchange company existing. In this case, create a tile based on the company information.
      dataExchanges.push({
        activeCustomerIds: null,
        address: dataExchangeCompany.address.getConcatenatedAddress(),
        deliveryNoteStatus: dataExchangeCompany.statusDeliveryNote,
        furtherInformation: dataExchangeCompany.furtherInformation,
        id: dataExchangeCompany.id,
        invoiceStatus: dataExchangeCompany.statusInvoice,
        knownIssues: dataExchangeCompany.knownIssues,
        name: dataExchangeCompany.name,
        overallStatus: dataExchangeCompany.statusDeliveryNote,
        rank: dataExchangeCompany.rank,
        receiverId: null,
        senderId: dataExchangeCompany.id,
      });
    }

    dataExchanges = dataExchanges.map((dataExchange) =>
      MapperService.addSearchString(dataExchange, [
        'id',
        'senderId',
        'receiverId',
        'name',
        'address',
        'activeCustomerIds',
        'knownIssues',
        'furtherInformation',
        'deliveryNoteStatusLabel',
        'invoiceStatusLabel',
        'currentIssues',
      ]),
    );
    dataExchanges = sortByKey(dataExchanges, 'rank', true);

    this.setState({
      dataExchanges,
    });
  }

  filterDataExchanges() {
    const filteredDataExchanges = this.state.dataExchanges.filter(
      (dataExchange) => {
        if (
          this.props.query !== '' &&
          !dataExchange.searchString.includes(this.props.query.toLowerCase())
        )
          return false;

        // Only filter the activated data exchanges by the selected company
        if (
          dataExchange.overallStatus ===
            DataExchangeCompany.STATUS.ACTIVATED.KEY &&
          this.props.selectedCompany &&
          this.props.selectedCompany !== dataExchange.receiverId
        ) {
          return false;
        }

        return true;
      },
    );

    this.setState({
      filteredDataExchanges,
    });
  }

  handleChangeCompany = (companyId) => {
    Log.info(
      'Change filter value of company',
      {
        from: this.props.selectedCompany,
        to: companyId,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter company', Log.FEATURE.SUPPLIER_OVERVIEW);

    this.props.setDataExchanges_selectedCompany(companyId);
  };
  exportExcel = () => {
    const data = this.state.filteredDataExchanges.map((dataExchange) => {
      return [
        dataExchange.name,
        dataExchange.address,
        DataExchangeCompany.getStatusLabel(dataExchange.overallStatus),
        DataExchangeCompany.getStatusLabel(dataExchange.deliveryNoteStatus),
        DataExchangeCompany.getStatusLabel(dataExchange.invoiceStatus),
        dataExchange.activeCustomerIds,
        dataExchange.knownIssues,
        dataExchange.furtherInformation,
      ];
    });

    data.unshift([
      'Name',
      'Adresse',
      'Status',
      'Lieferungen',
      'Rechnungen',
      'Kundennummern',
      'Bekannte Probleme',
      'Weitere Informationen',
    ]);

    ExportService.exportExcel(data);
  };
  requestInformation = () => {
    Log.info(
      'Request information',
      { requestInformationSelection: !this.state.requestInformationSelection },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Request information',
      Log.FEATURE.SUPPLIER_OVERVIEW,
    );

    this.setState({
      requestInformationSelection: !this.state.requestInformationSelection,
    });

    if (
      !(
        this.state.requestInformationSelection &&
        this.state.requestInformationSelectionList.length > 0
      )
    ) {
      return;
    }

    Log.info(
      'Send request information',
      {
        requestInformationSelectionList:
          this.state.requestInformationSelectionList,
      },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Send request information',
      Log.FEATURE.SUPPLIER_OVERVIEW,
    );

    const body =
      'Bitte informieren Sie mich über den aktuellen Stand der Lieferanten:\n\n' +
      this.state.requestInformationSelectionList
        .map((id) => {
          const dataExchange = this.state.filteredDataExchanges.find(
            (dataExchange) => dataExchange.senderId === id,
          );
          return dataExchange.name + ' (' + dataExchange.address + ')';
        })
        .join('\n');

    globalThis.location =
      'mailto:' +
      UniversalCommunication.getSupportContact().email +
      '?subject=Information Lieferanten Status&body=' +
      encodeURIComponent(body);
  };
  handleRequestInformationSelectionChange = (id) => {
    let { requestInformationSelectionList } = this.state;

    if (requestInformationSelectionList.includes(id)) {
      Log.info(
        'Remove request information selection',
        { id },
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Remove request information selection',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      requestInformationSelectionList = requestInformationSelectionList.filter(
        (index) => index !== id,
      );
    } else {
      Log.info(
        'Add request information selection',
        { id },
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Add request information selection',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      requestInformationSelectionList.push(id);
    }

    this.setState({
      requestInformationSelectionList,
    });
  };
  handleCheckboxChange = (event) => {
    Log.info(
      'Change supplier overview warning accepted',
      { to: event.target.checked },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change supplier overview warning accepted',
      Log.FEATURE.SUPPLIER_OVERVIEW,
    );

    this.setState({
      supplierOverviewWarningAccepted: event.target.checked,
    });
  };
  supplierOverviewWarningFormSuccess = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.supplierOverviewWarningAccepted) {
      LocalStorageService.setLocalStorage(
        LocalStorageService.SUPPLIER_OVERVIEW_WARNING_ACCEPTED,
        true,
      );
    }

    this.setState({
      supplierOverviewWarningOpen: false,
    });
  };
  supplierOverviewWarningFormAbort = () => {
    this.setState({
      supplierOverviewWarningOpen: false,
    });
  };

  getCompanyChipList() {
    return (
      <>
        <div className="mb-2 font-bold">Für welche Firma?</div>
        <SelectCompany
          placeholder="Alle Firmen"
          value={this.props.selectedCompany}
          onChange={(companyId) => {
            this.handleChangeCompany(companyId);
          }}
        />
      </>
    );
  }

  getCurrentIssuesNotification() {
    if (
      !this.state.filteredDataExchanges.find(
        (dataExchange) => dataExchange.currentIssues,
      )
    ) {
      return;
    }

    return (
      <div className="bg-error100 mt-4 flex w-fit items-center gap-2 rounded-lg p-2 text-red-700">
        <InfoIcon />
        <div>
          Im Moment gibt es Übertragungsschwierigkeiten bei verschiedenen
          Lieferanten.
        </div>
      </div>
    );
  }

  getRequestInformationSelectionIconButton() {
    if (!this.state.requestInformationSelection) {
      return (
        <IconButton
          tooltipTitle="Nähere Informationen zu den ausgewählten Lieferanten einholen."
          size="large"
          onClick={this.requestInformation}
        >
          <QuestionMarkIcon className="text-primary500 size-6" />
        </IconButton>
      );
    }

    return (
      <IconButton
        tooltipTitle="E-Mail öffnen."
        size="large"
        onClick={this.requestInformation}
      >
        <CheckIcon className="text-primary500 size-6" />
      </IconButton>
    );
  }

  getLoadingState() {
    const { dataExchangesLoading, dataExchangeCompaniesLoading } =
      this.props.dataExchanges;

    if (
      [dataExchangeCompaniesLoading, dataExchangesLoading].includes(
        LOADING_STATE.FAILED,
      )
    ) {
      return LOADING_STATE.FAILED;
    }

    if (
      [dataExchangesLoading, dataExchangeCompaniesLoading].some((state) =>
        [LOADING_STATE.LOADING, LOADING_STATE.NOT_LOADED].includes(state),
      )
    ) {
      return LOADING_STATE.LOADING;
    }

    return LOADING_STATE.SUCCEEDED;
  }

  render() {
    const loadingState = this.getLoadingState();

    if (loadingState === LOADING_STATE.LOADING) {
      <Spinner title="Lade Lieferantendaten…" className="absolute my-auto" />;
    }

    if (loadingState === LOADING_STATE.FAILED) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <ErrorIcon className="mr-2" />
          <div>Lieferantenübersicht konnte nicht geladen werden.</div>
        </div>
      );
    }

    return (
      <div className="flex h-full w-full flex-col">
        <div className="main-padding">
          <div className="mb-4 flex items-center justify-between">
            <div className="w-80">
              <SearchInput
                autoFocus
                value={this.props.query}
                productAnalyticsFeature={Log.FEATURE.SUPPLIER_OVERVIEW}
                onChange={(params) => this.props.setDataExchanges_query(params)}
              />
            </div>
            <div className="flex items-center gap-4">
              <IconButton
                tooltipTitle="Excel Download"
                size="large"
                onClick={this.exportExcel}
              >
                <BackupTableIcon className="text-primary500 size-6" />
              </IconButton>
              {this.getRequestInformationSelectionIconButton()}
            </div>
          </div>
          {!this.props.externalSupplierOverview && (
            <>
              <div className="max-w-80">
                <div className="mb-2 font-bold">Für welche Firma?</div>
                <SelectCompany
                  placeholder="Alle Firmen"
                  value={this.props.selectedCompany}
                  onChange={(companyId) => {
                    this.handleChangeCompany(companyId);
                  }}
                />
              </div>
              {this.getCurrentIssuesNotification()}
              <div className="mt-4 mb-4 min-h-px w-full bg-gray-200" />
            </>
          )}
          <DataExchangeTiles
            filteredDataExchanges={this.state.filteredDataExchanges}
            requestInformationSelection={this.state.requestInformationSelection}
            requestInformationSelectionList={
              this.state.requestInformationSelectionList
            }
            onChangeRequestInformationSelection={
              this.handleRequestInformationSelectionChange
            }
          />
          <div
            className="min-h-8"
            /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
            /* A better solution would be to make the parent container min-h-fit so that the padding of main-padding is applied. */
            /* However, min-h-fit seems to not work with h-fill or generally with flexbox and flex-1. */
          />
        </div>
        <BasicForm
          title="Ansprechpartner bei unseren Lieferanten"
          open={this.state.supplierOverviewWarningOpen}
          formSuccess={this.supplierOverviewWarningFormSuccess}
          formAbort={this.supplierOverviewWarningFormAbort}
          submitButtonTitle="Verstanden"
        >
          <div>
            Wir erhalten die Lieferscheine über eine direkte Verbindung zwischen
            dem System der Lieferanten/Hersteller und dem VESTIGAS-System.
          </div>
          <div>
            Durch den automatisierten Ablauf können viele Mitarbeitende der
            Lieferanten/Hersteller (z.B. im Verkaufsraum oder an der Waage)
            VESTIGAS nicht kennen.
          </div>
          <div>
            Solltest du dich mit Lieferanten/Herstellern über VESTIGAS
            austauschen wollen, können wir dir gerne die richtigen
            Ansprechpersonen vermitteln.
          </div>
          <div className="text-bold my-2 flex items-center gap-1">
            <InfoIcon className="text-warningBase" />
            Hiermit habe ich verstanden, dass nur ein ausgewählter Kreis an
            Mitarbeitenden bei den Lieferanten VESTIGAS kennt.
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.supplierOverviewWarningAccepted}
                onChange={this.handleCheckboxChange}
              />
            }
            label="Diesen Hinweis nicht mehr anzeigen"
          />
        </BasicForm>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(DataExchanges),
  'Lieferantenübersicht konnten nicht geladen werden.',
);
