import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from '@xstate/store/react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector as useReduxSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router';

import LocalStorageService from '~/services/localStorage.service';

import { globalState } from '~/modules/applicationState';
import { RoutingProgressIndicator } from '~/modules/router';

import { MainNav } from './MainNav';
import { TopBar } from './TopBar';
import { useMainNavItems } from './useMainNavItems';

const selectBackdrop = createSelector(
  [(state) => state.backdrop],
  (backdrop) => ({
    message: backdrop.message,
    open: backdrop.open,
  }),
);

export const AppLayout = memo(() => {
  const { t } = useTranslation();

  const location = useLocation();

  const backdrop = useReduxSelector(selectBackdrop);

  const [isNavCollapsed, setIsNavCollapsed] = useState(
    LocalStorageService.getBooleanFromLocalStorage(
      LocalStorageService.HIDE_DRAWER,
    ),
  );
  const [shouldNavBeCollapsed, setShouldNavBeCollapsed] = useState(false);

  useEffect(() => {
    // For small screens, we collapse the nav by default w/o overriding the user setting.
    const handleResize = () => {
      setShouldNavBeCollapsed(window.innerWidth <= 1024);
    };

    handleResize();

    // TODO: provide custom hook with throttled resize observer
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const drawerWidthOpen = 288;
  const drawerWidthClosed = 110;
  const drawerWidth = useMemo(() => {
    return shouldNavBeCollapsed || isNavCollapsed
      ? drawerWidthClosed
      : drawerWidthOpen;
  }, [shouldNavBeCollapsed, isNavCollapsed]);

  const mainNavItems = useMainNavItems();

  // Enforce limit of toasts.
  const { toasts } = useToasterStore();
  useEffect(() => {
    for (const t of toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, index) => index >= 5)) {
      toast.dismiss(t.id); // Dismiss – Use toast.remove(t.id) removal without animation
    }
  }, [toasts]);

  const pageTitle = useSelector(
    globalState,
    (state) => state.context.pageTitle,
  );
  const title = [pageTitle, t('app.meta.title')].filter(Boolean).join(' | ');
  const description = t('app.meta.description');

  return (
    <div className="relative flex h-screen w-screen">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <RoutingProgressIndicator key={location.pathname} />
      <Backdrop
        open={Boolean(backdrop.open)}
        sx={{
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          textShadow: `0 0 0.75vw black`,
          transitionProperty: 'opacity',
          transitionTimingFunction: 'ease-out',
          zIndex: (theme) => theme.zIndex.snackbar,
        }}
      >
        <CircularProgress color="inherit" className="mr-4" />
        {backdrop.message}
      </Backdrop>
      <Toaster
        toastOptions={{
          className: 'rounded-md max-w-[60vw] py-1 pl-4',
        }}
      />
      <CssBaseline />

      <TopBar drawerWidth={drawerWidth} />
      <MainNav
        drawerWidth={drawerWidth}
        isNavCollapsed={shouldNavBeCollapsed || isNavCollapsed}
        items={mainNavItems}
        setIsNavCollapsed={setIsNavCollapsed}
      />
      <main
        className="will-change-width flex-1 overflow-auto"
        style={{
          height: `calc(100% - 63px)`,
          marginTop: '63px',
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Outlet />
      </main>
    </div>
  );
});

AppLayout.displayName = 'AppLayout';
