import { cloneDeep, mergeWith } from 'lodash-es';
import { validate as uuidvalidate } from 'uuid';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import store from '~/redux/store';
import { saveUserCompany } from '~/redux/userinfoSlice';

import CostCenterService from '~/services/costCenter.service';
import { camelcaseKeysFromApi } from '~/services/kyClient';
import SiteService from '~/services/site.service';
import UserService from '~/services/user.service';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import BilledItem from '~/models/billingState/BilledItem';
import CustomData from '~/models/customData/CustomData';
import DeliveryNoteAttachmentHandler from '~/models/deliveries/DeliveryNoteAttachmentHandler';
import ShippingMark from '~/models/deliveries/ShippingMark';
import Value from '~/models/deliveries/Value';
import ValueGroup from '~/models/deliveries/ValueGroup';
import { FILTER_DATA_TYPE_BACKEND } from '~/models/filters/constants';
import Company from '~/models/masterdata/Company';
import CostCenter from '~/models/masterdata/CostCenter';
import Site from '~/models/masterdata/Site';
import TradeContact from '~/models/masterdata/TradeContact';
import User from '~/models/masterdata/User';

import { unique } from '~/utils/array';
import Log from '~/utils/logging';
import {
  promiseAllSettledThrottled,
  promiseAllThrottled,
} from '~/utils/promise';
import { promiseHandler } from '~/utils/promiseHandler';

import DeliveryNoteParser from '~/parser/DeliveryNoteParser';

import { CHANGES_LIST_INCLUDED_PATHS } from '../constants/changes-list-included-paths';

import {
  COMBINED_STATE,
  CONTRACT_ROLE,
  INCOTERMS,
  MERGE_EXCLUDED_KEYS,
  PROCESS_CATEGORY,
  PROCESS_ROLE,
  PROCESS_STATE,
  PROPERTY,
} from './constants';
import {
  calculateCombinedState,
  calculateProcessState,
  calculateTotalWeight,
  getArticles,
  getBuyerOrderReferences,
  getConstructionComponents,
  getConstructionPlans,
  getDeliveryDescription,
  getMainArticle,
  getProcessCategory,
  getSellerOrderReferences,
  initCustomData,
  initPermittedUsers,
  setChangesForPath,
} from './utils';

export default class DeliveryNote {
  constructor(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);

    DeliveryNoteParser.parseAssetMain(assetMain);

    // Support both snake_case and camelCase keys
    const getField = (object, snakeCase, camelCase) => {
      return object?.[snakeCase] ?? object?.[camelCase];
    };

    this.id = assetMain?._id ?? assetMain?.id ?? '';
    this.number =
      assetMain?.asset_state?.body?.header?.id ??
      assetMain?.assetState?.body?.header?.id ??
      '';
    this.dlnDate =
      assetMain?.asset_state?.body?.header?.date ??
      assetMain?.assetState?.body?.header?.date ??
      '';
    this.createdOn = getField(assetMain, 'created_on', 'createdOn') ?? '';
    this.deliveryDate =
      getField(assetMain, 'ts_arrived', 'tsArrived') ??
      getField(assetMain, 'ts_delivered', 'tsDelivered') ??
      getField(assetMain, 'created_on', 'createdOn') ??
      '';
    this.modifiedOn = getField(assetMain, 'modified_on', 'modifiedOn') ?? '';

    this.sOus = assetMain?.sOus ?? []; // Special field for Strabag organizational units only: returns OUs only up to a particular level.

    this.sellerOrderReferences = getSellerOrderReferences(assetMain);
    this.buyerOrderReferences = getBuyerOrderReferences(assetMain);
    this.constructionPlans = getConstructionPlans(assetMain);
    this.constructionComponents = getConstructionComponents(assetMain);
    this.shippingMarks = (
      assetMain?.asset_state?.body?.transaction?.delivery?.shipping_marks ??
      assetMain?.assetState?.body?.transaction?.delivery?.shippingMarks ??
      []
    ).map((shippingMark) => new ShippingMark(shippingMark));

    const deliveryTerms =
      assetMain?.asset_state?.body?.transaction?.agreement?.delivery_terms ??
      assetMain?.assetState?.body?.transaction?.agreement?.deliveryTerms;
    this.deliveryType = this.computeDeliveryType_safe(deliveryTerms?.code);

    const shipFrom =
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from ??
      assetMain?.assetState?.body?.transaction?.delivery?.shipFrom;
    const shipTo =
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_to ??
      assetMain?.assetState?.body?.transaction?.delivery?.shipTo;

    this.fromSite = new Site({
      address: shipFrom?.trade_address ?? shipFrom?.tradeAddress,
      issuerAssignedId:
        shipFrom?.trade_address?.issuer_assigned_id ??
        shipFrom?.tradeAddress?.issuerAssignedId ??
        null,
      name:
        shipFrom?.trade_address?.line_one ??
        shipFrom?.tradeAddress?.lineOne ??
        '',
      tradeContact: shipFrom?.trade_contact ?? shipFrom?.tradeContact,
    });

    this.toSiteSupplier = new Site({
      address: shipTo?.trade_address ?? shipTo?.tradeAddress,
      id: shipTo?.trade_address?.id ?? shipTo?.tradeAddress?.id,
      name:
        shipTo?.trade_address?.line_one ?? shipTo?.tradeAddress?.lineOne ?? '',
      tradeContact: shipTo?.trade_contact ?? shipTo?.tradeContact,
    });

    this.toSiteRecipient = this.getToSiteRecipient(assetMain);
    this.permittedToSites = this.getPermittedToSites(assetMain);
    this.permittedCostCenters = this.getPermittedCostCenters(assetMain);
    this.permittedUsers = [];

    const movement = shipFrom?.consignment?.movement;
    this.movementMeans = movement?.means ?? '';
    this.carrierLicensePlate =
      movement?.registration_id?.map((r) => r.id)?.join(', ') ??
      movement?.registrationId?.map((r) => r.id)?.join(', ') ??
      '';
    this.carrierVehicleNumber =
      movement?.registration_id?.map((r) => r.issuer_assigned_id)?.join(', ') ??
      movement?.registrationId?.map((r) => r.issuerAssignedId)?.join(', ') ??
      '';
    this.driver = new TradeContact(movement?.driver);

    const agreement =
      assetMain?.asset_state?.body?.transaction?.agreement ??
      assetMain?.assetState?.body?.transaction?.agreement;

    this.seller = new Company(
      agreement?.seller?.legal_organization ??
        agreement?.seller?.legalOrganization,
      agreement?.seller?.trade_contact ?? agreement?.seller?.tradeContact,
    );

    this.buyer = new Company(
      agreement?.buyer?.legal_organization ??
        agreement?.buyer?.legalOrganization,
      agreement?.buyer?.trade_contact ?? agreement?.buyer?.tradeContact,
    );

    const settlement =
      assetMain?.asset_state?.body?.transaction?.settlement ??
      assetMain?.assetState?.body?.transaction?.settlement;
    this.buyerId =
      settlement?.sales_account?.name ?? settlement?.salesAccount?.name ?? '';

    this.project =
      shipTo?.trade_address?.issuer_assigned_id ??
      shipTo?.tradeAddress?.issuerAssignedId ??
      null;

    const header =
      assetMain?.asset_state?.body?.header ??
      assetMain?.assetState?.body?.header;
    this.customData = new CustomData(
      header?.additional_party_data ?? header?.additionalPartyData,
    );

    this.carrierId =
      shipFrom?.consignment?.carrier?.legal_organization?.issuer_assigned_id ??
      shipFrom?.consignment?.carrier?.legalOrganization?.issuerAssignedId ??
      '';

    this.issuer = new Company(
      header?.issuer?.legal_organization ?? header?.issuer?.legalOrganization,
      header?.issuer?.trade_contact ?? header?.issuer?.tradeContact,
    );

    this.recipient = new Company(
      shipTo?.legal_organization ?? shipTo?.legalOrganization,
      shipTo?.trade_contact ?? shipTo?.tradeContact,
    );

    this.carrier = new Company(
      shipFrom?.consignment?.carrier?.legal_organization ??
        shipFrom?.consignment?.carrier?.legalOrganization,
      shipFrom?.consignment?.carrier?.trade_contact ??
        shipFrom?.consignment?.carrier?.tradeContact,
    );

    this.supplier = new Company(
      shipFrom?.legal_organization ?? shipFrom?.legalOrganization,
      shipFrom?.trade_contact ?? shipFrom?.tradeContact,
    );

    this.trader = new Company(
      agreement?.trader?.legal_organization ??
        agreement?.trader?.legalOrganization,
      agreement?.trader?.trade_contact ?? agreement?.trader?.tradeContact,
    );

    const freightForwarder =
      movement?.freight_forwarder ?? movement?.freightForwarder;
    this.freightForwarder = new Company(
      freightForwarder?.legal_organization ??
        freightForwarder?.legalOrganization,
      freightForwarder?.trade_contact ?? freightForwarder?.tradeContact,
    );

    this.processRolesOfUserCompany = this.getProcessRolesOfUserCompany();
    this.processCategory = this.getProcessCategory();

    this.articles = getArticles(assetMain);
    this.deliveredArticles = this.articles.filter(
      (article) => article.amount.value >= 0,
    );
    this.returnedArticles = this.articles.filter(
      (article) => article.amount.value < 0,
    );

    this.mainArticle = getMainArticle(this.articles);

    this.totalWeightDeliveredArticles = calculateTotalWeight(
      this.deliveredArticles,
      'weight',
    );
    this.totalWeightReturnedArticles = calculateTotalWeight(
      this.returnedArticles,
      'weight',
    );
    this.totalWeightNet = calculateTotalWeight(this.articles, 'weight');
    this.totalWeightGross = calculateTotalWeight(
      this.articles,
      'weighingInformation.gross.weight',
    );
    this.totalWeightTare = calculateTotalWeight(
      this.articles,
      'weighingInformation.tare.weight',
    );

    this.deliveryDescription = this.getDeliveryDescription();

    this.processState = calculateProcessState(assetMain);
    this.acceptStateSupplier =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleSupplier.acceptState,
        ),
      );
    this.acceptStateCarrier =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleCarrier.acceptState,
        ),
      );
    this.acceptStateRecipient =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleRecipient.acceptState,
        ),
      );
    this.acceptStateOnBehalfSupplier =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleOnBehalfSupplier.acceptState,
        ),
      );
    this.acceptStateOnBehalfCarrier =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleOnBehalfCarrier.acceptState,
        ),
      );
    this.acceptStateOnBehalfRecipient =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleOnBehalfRecipient.acceptState,
        ),
      );
    this.acceptState =
      AcceptStateCalculator.calculateOverallAcceptStateFromParties(
        this.acceptStateSupplier,
        this.acceptStateCarrier,
        this.acceptStateRecipient,
        this.acceptStateOnBehalfSupplier,
        this.acceptStateOnBehalfCarrier,
        this.acceptStateOnBehalfRecipient,
      );

    this.referencedInvoices = unique(
      this.articles.flatMap(({ billedItem }) => billedItem.invoiceIds),
    );
    this.settledStatus = BilledItem.calculateCombinedSettledStatus(
      this.articles.map(({ billedItem }) => billedItem.settledStatus),
    );
    this.combinedState = calculateCombinedState(
      this.acceptState,
      this.processState,
    );
    this.costCenters = unique(
      this.articles.map(({ costCenter }) => costCenter).filter(Boolean),
    );

    const execution =
      assetMain?.asset_state?.body?.transaction?.delivery?.execution ??
      assetMain?.assetState?.body?.transaction?.delivery?.execution ??
      {};

    this.execution = {
      arrived: execution.arrived,
      arrivedPlanned: execution.arrived_planned ?? execution.arrivedPlanned,
      beginExecution: execution.begin_execution ?? execution.beginExecution,
      beginExecutionPlanned:
        execution.begin_execution_planned ?? execution.beginExecutionPlanned,
      beginLoading: execution.begin_loading ?? execution.beginLoading,
      beginLoadingPlanned:
        execution.begin_loading_planned ?? execution.beginLoadingPlanned,
      beginUnloading: execution.begin_unloading ?? execution.beginUnloading,
      beginUnloadingPlanned:
        execution.begin_unloading_planned ?? execution.beginUnloadingPlanned,
      departure: execution.departure,
      departurePlanned:
        execution.departure_planned ?? execution.departurePlanned,
      endExecution: execution.end_execution ?? execution.endExecution,
      endExecutionPlanned:
        execution.end_execution_planned ?? execution.endExecutionPlanned,
      endLoading: execution.end_loading ?? execution.endLoading,
      endLoadingPlanned:
        execution.end_loading_planned ?? execution.endLoadingPlanned,
      endUnloading: execution.end_unloading ?? execution.endUnloading,
      endUnloadingPlanned:
        execution.end_unloading_planned ?? execution.endUnloadingPlanned,
    };

    this.comments = [];
    this.waitingTime = 0;
    this.regieTime = 0;
    this.palletAmount = 0;
    this.assignNotes(assetMain);

    // The user that is responsible for the change of the process state.
    this.processStateChangeUser = {
      arrived: new User({
        id: getField(assetMain, 'us_arrived', 'usArrived')?.split('_')?.pop(),
      }),
      cancelled: new User({
        id: getField(assetMain, 'us_cancelled', 'usCancelled')
          ?.split('_')
          ?.pop(),
      }),
      delivered: new User({
        id: getField(assetMain, 'us_delivered', 'usDelivered')
          ?.split('_')
          ?.pop(),
      }),
      inTransport: new User({
        id: getField(assetMain, 'us_inTransport', 'usInTransport')
          ?.split('_')
          ?.pop(),
      }),
      readyForOutput: new User({
        id: getField(assetMain, 'us_readyForOutput', 'usReadyForOutput')
          ?.split('_')
          ?.pop(),
      }),
    };

    this.assetChain = assetMain?.asset_chain ?? assetMain?.assetChain ?? [];
    this.history = {};
    this.recalculatedMain = null;
    this.changes = [];
    this.changesListIncludedPaths = cloneDeep(CHANGES_LIST_INCLUDED_PATHS);

    this.attachmentHandler = new DeliveryNoteAttachmentHandler();

    this.userActions = {
      requestSignatures: [],
      shareDeliveryNote: [],
    };

    this.asyncInitiated = false;

    this.fixParserErrors();
  }

  // Execute all async processes in parallel that are needed to fill the delivery note data
  async asyncInit() {
    // Don't run asyncInit multiple times as it can lead to weird bugs such as custom fields being displayed multiple time in the dln history.
    // For example, asyncInit is executed during dln bulk load and when loading dln in dln detail view.
    if (this.asyncInitiated) {
      return;
    }

    this.asyncInitiated = true;

    const processCategoryPromise = this.updateProcessCategory();
    const toSiteRecipientPromise = this.initToSiteRecipient();
    const permittedCostCentersPromise = this.initPermittedCostCenters();
    const permittedToSitesPromise = this.initPermittedToSites();
    const customDataDeliveryNotePromise = this.initCustomDataDeliveryNote();
    const customDataArticlePromise = this.initCustomDataArticles();
    const processStateChangeUserPromise = this.initProcessStateChangeUser();

    return promiseAllThrottled([
      processCategoryPromise,
      toSiteRecipientPromise,
      permittedToSitesPromise,
      permittedCostCentersPromise,
      customDataDeliveryNotePromise,
      customDataArticlePromise,
      processStateChangeUserPromise,
    ]);
  }

  // Execute all async processes in parallel that are needed to fill the delivery note data
  async asyncInitWithBulkEntities() {
    // Don't run asyncInit multiple times as it can lead to weird bugs such as custom fields being displayed multiple time in the dln history.
    // For example, asyncInit is executed during dln bulk load and when loading dln in dln detail view.
    if (this.asyncInitiated) {
      return;
    }

    this.asyncInitiated = true;

    const processCategoryPromise = this.updateProcessCategory();
    const toSiteRecipientPromise = this.initToSiteRecipientWithBulkSites();
    const permittedToSitesPromise = this.initPermittedToSitesWithBulkSites();
    const permittedCostCentersPromise =
      this.initPermittedCostCentersWithBulkCostCenters();
    const customDataDeliveryNotePromise = this.initCustomDataDeliveryNote();
    const customDataArticlePromise = this.initCustomDataArticles();
    const processStateChangeUserPromise =
      this.initProcessStateChangeUserWithBulkUsers();

    return promiseAllThrottled([
      processCategoryPromise,
      toSiteRecipientPromise,
      permittedToSitesPromise,
      permittedCostCentersPromise,
      customDataDeliveryNotePromise,
      customDataArticlePromise,
      processStateChangeUserPromise,
    ]);
  }

  assignNotes(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);

    const notes = assetMain?.assetState?.body?.transaction?.delivery?.note;

    if (notes)
      for (const note of notes) {
        if (note.type === 'comment_note') {
          this.comments.push(note.note?.content?.comment);
        }

        if (note.type === 'waiting_time_note') {
          this.waitingTime = note.note?.content?.time;
        }

        if (note.type === 'regie_time_note') {
          this.regieTime = note.note?.content?.time;
        }

        if (note.type === 'amount_pallet_note') {
          this.palletAmount = note.note?.content?.amount;
        }
      }
  }

  getDeliveryDescription() {
    return getDeliveryDescription({
      deliveredArticles: this.deliveredArticles,
      returnedArticles: this.returnedArticles,
      totalWeightDelivered: this.totalWeightDeliveredArticles,
      totalWeightReturned: this.totalWeightReturnedArticles,
    });
  }

  hasMultipleLogisticsPackages() {
    const logisticsPackage = ValueGroup.getCurrentValue(
      this.articles?.[0]?.logisticsPackage,
    );

    if (!logisticsPackage) {
      return false;
    }

    for (const article of this.articles) {
      if (
        ValueGroup.getCurrentValue(article.logisticsPackage) !==
        logisticsPackage
      ) {
        return true; // Found a different logistics package
      }
    }

    return false; // All logistics packages are the same
  }

  computeDeliveryType_safe(code) {
    if (!code) {
      return '';
    }

    return this.computeDeliveryType(code);
  }

  // computes human-readable string that can be displayed
  computeDeliveryType(code) {
    const incoterm = Object.keys(DeliveryNote.INCOTERMS).find(
      (x) => DeliveryNote.INCOTERMS[x].KEY === code,
    );

    if (!incoterm) {
      return code;
    }

    return DeliveryNote.INCOTERMS[incoterm].DESCRIPTION;
  }

  static getDeliveryTypeCode(description) {
    const incoterm = Object.keys(DeliveryNote.INCOTERMS).find(
      (x) => DeliveryNote.INCOTERMS[x].DESCRIPTION === description,
    );

    if (!incoterm) {
      return null;
    }

    return DeliveryNote.INCOTERMS[incoterm].KEY;
  }

  // the mapped site name (from the buyer site) is stored in purchase_account after the user has signed the dln before
  // that, the buyer site id can be retrieved as fallback from the sgw field

  // This is shown as "Bestätigter Lieferort" - this concept doesn't exist in our dln - we use the settlement info as a
  // proxy. this is almost always only written when the user signs. it could theoretically be written by the supplier,
  // but this is never the case.
  getToSiteRecipient(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);

    if (!assetMain) {
      return new Site();
    }

    const settlementId =
      assetMain?.assetState?.body?.transaction?.logisticsPackage?.[0]
        ?.lineItem?.[0]?.settlement?.purchaseAccount?.id;

    if (settlementId) {
      return new Site({
        id: uuidvalidate(settlementId) ? settlementId : null, // validation is necessary because earlier, an id written by customers was used in this field
        name: assetMain?.assetState?.body?.transaction?.logisticsPackage?.[0]
          ?.lineItem?.[0]?.settlement?.purchaseAccount?.name,
      });
    }

    // return empty site so nothing is shown in table
    return new Site({});
  }

  getPermittedToSites(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);

    if (!assetMain?.sgw) {
      return [];
    }

    const sgwIds = assetMain?.sgw
      ?.filter((item) => item.type === 'pr')
      ?.map((item) => item.typeId);

    return sgwIds.map((id) => new Site({ id }));
  }

  getPermittedCostCenters(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);

    if (!assetMain?.sgw) {
      return [];
    }

    const sgwIds = assetMain?.sgw
      ?.filter((item) => item.type === 'cc')
      ?.map((item) => item.typeId);

    return sgwIds.map((id) => new CostCenter({ id }));
  }

  fixParserErrors() {
    this.carrierLicensePlate =
      this.carrierLicensePlate === 'null' ? '' : this.carrierLicensePlate;
  }

  // TODO: this is just a translation function; it could as well take the translated string from a simple object {supplier: 'Ausgehende Lieferung',}
  getCategoryDescription() {
    const processCategory = Object.keys(DeliveryNote.PROCESS_CATEGORY).find(
      (x) => DeliveryNote.PROCESS_CATEGORY[x].KEY === this.processCategory,
    );

    if (!processCategory) {
      throw new EnumValueNotFoundException(
        'Invalid process role: ' + this.processCategory,
      );
    }

    return DeliveryNote.PROCESS_CATEGORY[processCategory].STRING;
  }

  static getProcessRole(roleKey) {
    const processRole = Object.keys(DeliveryNote.PROCESS_ROLE).find(
      (x) => DeliveryNote.PROCESS_ROLE[x].KEY === roleKey,
    );

    if (!processRole) {
      throw new EnumValueNotFoundException('Invalid process role: ' + roleKey);
    }

    return DeliveryNote.PROCESS_ROLE[processRole].STRING;
  }

  static getProcessCategoryKeyFromString(category) {
    const processCategory = Object.keys(DeliveryNote.PROCESS_CATEGORY).find(
      (x) => DeliveryNote.PROCESS_CATEGORY[x].STRING === category,
    );

    return DeliveryNote.PROCESS_CATEGORY[processCategory].KEY;
  }

  getProcessRolesOfUserCompany() {
    const company = store.getState()?.userinfo?.userinfo?.company;

    const processRoles = {
      carrier: company?.id === this.carrier?.id,
      recipient: company?.id === this.recipient?.id,
      supplier: company?.id === this.supplier?.id,
    };

    if (
      processRoles.supplier ||
      processRoles.carrier ||
      processRoles.recipient
    ) {
      return processRoles;
    }

    // as fallback check via company account
    return {
      carrier: company?.companyAccount === this.carrier?.companyAccount,
      recipient: company?.companyAccount === this.recipient?.companyAccount,
      supplier: company?.companyAccount === this.supplier?.companyAccount,
    };
  }

  getProcessCategory() {
    return getProcessCategory(this.processRolesOfUserCompany);
  }

  // if process category is none, this could be due to the user company not being loaded yet
  // therefore, try again asynchronously and load the user company from the backend
  async updateProcessCategory() {
    if (this.processCategory !== DeliveryNote.PROCESS_CATEGORY.NONE.KEY) {
      return;
    }

    if (!store.getState()?.userinfo?.userinfo?.company) {
      const [company, error] = await promiseHandler(UserService.getCompany());

      if (error) {
        throw error;
      }

      store.dispatch(saveUserCompany(company));
    }

    this.processRolesOfUserCompany = this.getProcessRolesOfUserCompany();
    this.processCategory = this.getProcessCategory();
  }

  async initToSiteRecipient() {
    if (this.toSiteRecipient.name || !this.toSiteRecipient.id) {
      return;
    }

    const [site, error] = await promiseHandler(
      SiteService.getSiteById(this.toSiteRecipient.id),
    );

    if (error) {
      throw error;
    }

    if (site === null) {
      return;
    }

    this.toSiteRecipient = site;
  }

  async initToSiteRecipientWithBulkSites() {
    if (this.toSiteRecipient.name || !this.toSiteRecipient.id) {
      return;
    }

    const site = SiteService.getSiteFromSitesBulk(this.toSiteRecipient.id);

    if (site === null) {
      return;
    }

    this.toSiteRecipient = site;
  }

  async initPermittedToSites() {
    for (let index = 0; index < this.permittedToSites.length; index++) {
      const [site, error] = await promiseHandler(
        SiteService.getSiteById(this.permittedToSites[index].id),
      );

      if (error) {
        throw error;
      }

      if (site === null) {
        return;
      }

      this.permittedToSites[index] = site;
    }
  }

  async initPermittedToSitesWithBulkSites() {
    for (let index = 0; index < this.permittedToSites.length; index++) {
      const site = SiteService.getSiteFromSitesBulk(
        this.permittedToSites[index].id,
      );

      if (site === null) {
        return;
      }

      this.permittedToSites[index] = site;
    }
  }

  async initPermittedCostCenters() {
    for (let index = 0; index < this.permittedCostCenters.length; index++) {
      const [costCenter, error] = await promiseHandler(
        CostCenterService.getCostCenterById(
          this.permittedCostCenters[index].id,
        ),
      );

      if (error) {
        throw error;
      }

      if (costCenter === null) {
        return;
      }

      this.permittedCostCenters[index] = costCenter;
    }
  }

  async initPermittedCostCentersWithBulkCostCenters() {
    for (let index = 0; index < this.permittedCostCenters.length; index++) {
      const costCenter = CostCenterService.getCostCenterFromCostCentersBulk(
        this.permittedCostCenters[index].id,
      );
      if (costCenter === null) {
        return;
      }

      this.permittedCostCenters[index] = costCenter;
    }
  }

  async initPermittedUsers() {
    this.permittedUsers = await initPermittedUsers(
      this.permittedUsers,
      this.permittedToSites,
      this.permittedCostCenters,
    );
  }

  async initCustomDataDeliveryNote() {
    const paths = await initCustomData(
      this.customData,
      this.changesListIncludedPaths,
    );

    this.changesListIncludedPaths = paths;
  }

  async initCustomDataArticles() {
    const promises = this.articles.map(async ({ customData }) => {
      const paths = await initCustomData(
        customData,
        this.changesListIncludedPaths,
      );

      this.changesListIncludedPaths = paths;
    });

    return promiseAllThrottled(promises);
  }

  async initProcessStateChangeUser() {
    const promises = [];
    const keyMap = new Map(); // Keep track of which promise corresponds to which key

    for (const [key, { id }] of Object.entries(this.processStateChangeUser)) {
      if (!id) {
        // Skip if the process state hasn't been set yet by a user but by the system.
        continue;
      }

      if (id === User.ASSET_CREATOR_USER_ID) {
        // The "asset creator" is a special user that is used as first user in the delivery chain.
        // It is not a real user and should not be displayed in the delivery note.
        // Trying to load it from the backend will return a 403 error.
        continue;
      }

      keyMap.set(promises.length, key); // Store the key mapped to the promise index

      promises.push(UserService.getUserById(id));
    }

    const results = await promiseAllSettledThrottled(promises);

    for (const [index, result] of results.entries()) {
      const key = keyMap.get(index);
      if (result.status === 'fulfilled' && result.value) {
        this.processStateChangeUser[key] = result.value;
      }
      // Skip rejected promises or null values
    }
  }

  async initProcessStateChangeUserWithBulkUsers() {
    for (const [key, value] of Object.entries(this.processStateChangeUser)) {
      if (!value.id) {
        // Skip if the process state hasn't been set yet by a user but by the system.
        continue;
      }

      const user = UserService.getUserFromUsersBulk(value.id);

      if (!user) {
        // Case can happen, e.g. if the user is from another company account.
        continue;
      }

      this.processStateChangeUser[key] = user;
    }
  }

  initUserActions() {
    this.userActions.requestSignatures =
      store.getState()?.userinfo?.userActions?.[this.id]?.requestSignatures ??
      [];
    this.userActions.shareDeliveryNote =
      store.getState()?.userinfo?.userActions?.[this.id]?.shareDeliveryNote ??
      [];
  }

  addChainToHistory(assetChain, company, initial) {
    const convertAssetChainToMain = ({ body, ...rest }) => ({
      assetState: {
        body,
      },
      ...rest,
    });

    const updateAssetMain = (assetMain, assetChain) =>
      mergeWith({}, assetMain, assetChain, (mainValue, chainValue) => {
        // if asset chain contains null, it has NOT been updated
        if (chainValue == null) {
          return mainValue;
        }
      });

    const assetMain = convertAssetChainToMain(assetChain);
    this.recalculatedMain = updateAssetMain(this.recalculatedMain, assetMain);

    const updatedDln = new DeliveryNote(this.recalculatedMain);

    if (initial) {
      this.history = updatedDln;
      return;
    }

    this.mergeWithContext(
      this.history,
      updatedDln,
      assetChain.createdOn,
      company,
    );
  }

  mergeWithContext(currentObject, updatedObject, datetime, company) {
    mergeWith(
      currentObject,
      updatedObject,
      (currentValue, updatedValue, key) => {
        if (DeliveryNote.MERGE_EXCLUDED_KEYS.includes(key)) {
          return currentValue;
        }

        if (!Value.isSimpleValue(updatedValue)) {
          this.mergeWithContext(currentValue, updatedValue, datetime, company);
          return currentValue;
        }

        const updatedValueObject = new Value(updatedValue, datetime, company);

        if (!ValueGroup.isValueGroup(currentValue)) {
          const currentValueObject = new Value(currentValue, null, null);
          currentValue = new ValueGroup();
          currentValue.initial = currentValueObject;
        }

        if (!currentValue.latestValueEquals(updatedValue)) {
          currentValue.history.push(updatedValueObject);
        }

        return currentValue;
      },
    );
  }

  mergeHistory() {
    mergeWith(this, this.history, DeliveryNote.mergeHistoryCustomizer);
  }

  static mergeHistoryCustomizer = (currentValue, historyValue, key) => {
    // due to object being passed by reference, history and current values might have already been merged (e.g. mainArticle and article.*, or Article.amount and Article.weight)
    if (ValueGroup.isValueGroup(currentValue)) {
      return currentValue;
    }

    if (DeliveryNote.MERGE_EXCLUDED_KEYS.includes(key)) {
      return currentValue;
    }

    if (!ValueGroup.isValueGroup(historyValue)) {
      if (!Value.isSimpleValue(currentValue)) {
        mergeWith(
          currentValue,
          historyValue,
          DeliveryNote.mergeHistoryCustomizer,
        );
      }

      return currentValue;
    }

    if (
      Value.isSimpleValue(currentValue) ||
      // if the current value is an empty array and the corresponding value from the history is a value group, it is treated as a "real value".
      // this is needed for the comments prop as it can be an empty array in this (dln) so that it is not clear whether this array should be a ValueGroup (which is checked by this special condition)
      (Array.isArray(currentValue) &&
        currentValue.length === 0 &&
        ValueGroup.isValueGroup(historyValue))
    ) {
      if (!ValueGroup.isValueGroup(historyValue)) {
        return currentValue;
      }

      historyValue.current = new Value(currentValue, null);
      return historyValue;
    }
  };

  setChanges(ignoreInitialValues) {
    let changes = [...this.changes];

    for (const includedPath of this.changesListIncludedPaths) {
      changes = [
        ...setChangesForPath({
          changes,
          object: this.history,
          path: includedPath.PATH,
          name: includedPath.NAME,
          formatter: includedPath.FORMATTER,
          ignoreInitialValue:
            ignoreInitialValues && includedPath.IGNORE_INITIAL_VALUE,
        }),
      ];
    }

    this.changes = changes;
  }

  static getPropertyString(key) {
    const property = Object.keys(DeliveryNote.PROPERTY).find(
      (x) => DeliveryNote.PROPERTY[x].KEY === key,
    );

    if (!property) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid property: ' + key),
      );
      return key;
    }

    return DeliveryNote.PROPERTY[property].STRING;
  }

  static getDataType(key) {
    const property = Object.keys(DeliveryNote.PROPERTY).find(
      (x) => DeliveryNote.PROPERTY[x].KEY === key,
    );

    if (!property) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid property: ' + key),
      );
      return key;
    }

    return (
      DeliveryNote.PROPERTY[property].DATA_TYPE ??
      FILTER_DATA_TYPE_BACKEND.STRING
    );
  }

  static getProcessStateString(key) {
    const processState = Object.keys(DeliveryNote.PROCESS_STATE).find(
      (x) => DeliveryNote.PROCESS_STATE[x].API_KEY === key,
    );

    if (!processState) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + key),
      );
      return key;
    }

    return DeliveryNote.PROCESS_STATE[processState].STRING;
  }

  static getProcessStateKeyByString(string) {
    const processState = Object.keys(DeliveryNote.PROCESS_STATE).find(
      (x) => DeliveryNote.PROCESS_STATE[x].STRING === string,
    );

    if (!processState) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + string),
      );
      return string;
    }

    return DeliveryNote.PROCESS_STATE[processState].API_KEY;
  }

  static getProcessStateOptions() {
    return [
      DeliveryNote.PROCESS_STATE.READY_FOR_OUTPUT.STRING,
      DeliveryNote.PROCESS_STATE.IN_TRANSPORT.STRING,
      DeliveryNote.PROCESS_STATE.ARRIVED.STRING,
      DeliveryNote.PROCESS_STATE.CANCELLED.STRING,
      DeliveryNote.PROCESS_STATE.DELIVERED.STRING,
    ];
  }

  static getById(id) {
    // FIXME: this won't work anymore with react-query
    return store
      .getState()
      ?.deliveryNotes.deliveryNotes.find((dln) => dln.id === id);
  }

  static getNumberById(id) {
    // FIXME: this won't work anymore with react-query
    return store
      .getState()
      ?.deliveryNotes.deliveryNotes.find((dln) => dln.id === id)?.number;
  }

  getBackendFormat() {
    return {
      context: 'DELNTE',
      header: {
        date: this.dlnDate.toISOString(),
        id: this.number,
        issuer: this.issuer ? this.issuer.getBackendFormat() : null,
      },
      transaction: {
        agreement: {
          buyer: this.buyer ? this.buyer.getBackendFormat() : null,
          delivery_terms: this.deliveryType
            ? { code: DeliveryNote.getDeliveryTypeCode(this.deliveryType) }
            : null,
          seller: this.seller ? this.seller.getBackendFormat() : null,
        },
        delivery: {
          note: this.comments.map((comment) => {
            return {
              note: { content: { comment } },
              type: 'comment_note',
            };
          }),
          ship_from: {
            ...(this.supplier ? this.supplier.getBackendFormat() : {}),
            consignment: {
              carrier: this.carrier ? this.carrier.getBackendFormat() : null,
              movement: {
                freight_forwarder: this.freightForwarder
                  ? this.freightForwarder.getBackendFormat()
                  : null,
                means: this.movementMeans,
                registration_id:
                  this.carrierLicensePlate || this.carrierVehicleNumber
                    ? [
                        {
                          id: this.carrierLicensePlate,
                          issuer_assigned_id: this.carrierVehicleNumber,
                        },
                      ]
                    : null,
              },
            },
            trade_address: this.fromSite.name
              ? { line_one: this.fromSite.name }
              : null,
          },
          ship_to: {
            ...(this.recipient ? this.recipient.getBackendFormat() : {}),
            trade_address: {
              issuer_assigned_id: this.project,
              line_one: this.toSiteSupplier.name,
            },
          },
        },
        logistics_package: [
          {
            id: '1',
            line_item: this.articles.map((article, index) => {
              return {
                ...article.getBackendFormat(
                  this.costCenters[0],
                  this.sellerOrderReferences,
                  this.buyerOrderReferences,
                  this.constructionComponents,
                  this.constructionPlans,
                ),
                id: index + 1,
              };
            }),
          },
        ],
        settlement: {
          sales_account: {
            name: this.buyerId,
            type_code: 'AVC',
          },
        },
      },
    };
  }

  static COMBINED_STATE = COMBINED_STATE;
  static CONTRACT_ROLE = CONTRACT_ROLE;
  static INCOTERMS = INCOTERMS;
  static MERGE_EXCLUDED_KEYS = MERGE_EXCLUDED_KEYS;
  static PROCESS_CATEGORY = PROCESS_CATEGORY;
  static PROCESS_ROLE = PROCESS_ROLE;
  static PROCESS_STATE = PROCESS_STATE;
  static PROPERTY = PROPERTY;
}
