import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'oidc-react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router';

import { ROUTE } from '~/constants/Route';

import store from '~/redux/store';

import ThirdPartyService from '~/services/thirdParty.service';

import { UpdatePrompt } from '~/modules/pwa';

import { isLocalhost } from '~/utils/environment';
// Initialize i18next
/* eslint-disable-next-line import/no-unassigned-import */
import '~/utils/i18n';
import { HotkeysProvider } from '~/utils/useHotkeys';

import { IdsRedirectPage } from '~/components/ids/IdsRedirectPage';
import { router } from '~/components/menu/Routes';
import { theme } from '~/ui/styles';
import '~/ui/styles/index.css';
import '~/ui/styles/legacy.css';

import AuthService from './services/auth.service';

if (!isLocalhost) {
  ThirdPartyService.initSentry();
}

ThirdPartyService.initMuiPro();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 10,
    },
  },
});

export const App = () => {
  if (globalThis.location.pathname === ROUTE.IDS_REDIRECT.ROUTE) {
    return <IdsRedirectPage />;
  }

  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <HotkeysProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <HelmetProvider>
                <CssBaseline />
                <AuthProvider {...(AuthService ? AuthService.oidcConfig : {})}>
                  <RouterProvider router={router} />
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </HelmetProvider>
            </ThemeProvider>
          </StyledEngineProvider>
          <UpdatePrompt />
        </HotkeysProvider>
      </QueryClientProvider>
    </ReduxProvider>
  );
};
