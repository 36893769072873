/**
 * Current user is working on a Mac machine based on his browser user agent data.
 */
export const isMac =
  (typeof navigator !== 'undefined' &&
    navigator?.platform?.toUpperCase()?.includes('MAC')) ??
  false;

/**
 * Current user is working on a Windows machine based on his browser user agent data.
 */
export const isWindows =
  (typeof navigator !== 'undefined' &&
    navigator?.platform?.toUpperCase()?.includes('WIN')) ??
  false;

/**
 * Current user is working on a Linux machine based on his browser user agent data.
 */
export const isLinux =
  (typeof navigator !== 'undefined' &&
    navigator?.platform?.toUpperCase()?.includes('LINUX')) ??
  false;

/**
 * Returns the current user's operating system based on his browser user agent data.
 */
export const getOperatingSystem = () => {
  if (isMac) {
    return 'macOS';
  }

  if (isWindows) {
    return 'Windows';
  }

  if (isLinux) {
    return 'Linux';
  }

  return undefined;
};

export type OperatingSystemName = ReturnType<typeof getOperatingSystem>;
