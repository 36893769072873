import { CssBaseline } from '@mui/material';
import ms from 'ms';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { getSearchBody } from '~/data/deliveryNote';

import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { dayjs } from '~/utils/datetime';

import {
  AdOverlay,
  DLNList,
  OrderInProcessIcon,
  OrderReadyIcon,
  PageHeader,
} from './components';
import { useQueryDeliveryNotesPickupScreen } from './useQueryDeliveryNotesPickupScreen';
import { dlnsForProcessState } from './utils';

export const PickupScreenBayWa = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW();

  const searchParams = getSearchBody({
    filterGroups: {
      dateRange: [
        dayjs().subtract(1, 'month').toISOString(),
        dayjs().add(1, 'week').toISOString(),
      ],
    },
  });

  const { data, isFetching, isLoading } = useQueryDeliveryNotesPickupScreen(
    {
      ...searchParams,
      limit: 200,
    },
    {
      refetchInterval: ms('10s'),
      refetchIntervalInBackground: true,
      staleTime: ms('10s'),
    },
  );
  const deliveryRows = data?.rows;

  console.log('-------------------------------------------------');

  const inProcess = dlnsForProcessState(
    deliveryRows,
    DeliveryNote.PROCESS_STATE.IMPORTED.STRING,
  );
  const readyForPickup = dlnsForProcessState(
    deliveryRows,
    DeliveryNote.PROCESS_STATE.READY_FOR_OUTPUT.STRING,
  );

  useEffect(() => {
    if (needRefresh) {
      // Automatically update the app (the service worker) when a new version is available.
      console.log('Auto-update service worker to latest version');
      void updateServiceWorker(true);
    }
  }, [needRefresh, updateServiceWorker]);

  return (
    <>
      <Helmet>
        <title>Abholer-Übersicht</title>
      </Helmet>
      <div className="flex h-screen w-full flex-col overflow-hidden">
        <CssBaseline />
        <AdOverlay />
        <PageHeader isFetching={isFetching} />
        <div className="flex flex-1">
          <DLNList
            dlns={inProcess}
            status="In Bearbeitung"
            prompt="— Bitte im Wartebereich warten —"
            icon={
              <OrderInProcessIcon
                className="inline-block align-top"
                style={{
                  height: '6vw',
                  marginRight: '1vw',
                  marginTop: '-0.9vw',
                }}
              />
            }
            style={{
              color: 'var(--grey500)',
            }}
            isLoading={isLoading}
          />
          <DLNList
            dlns={readyForPickup}
            status="Zur Abholung"
            prompt="Bitte einfahren!"
            icon={
              <OrderReadyIcon
                className="inline-block align-top"
                style={{
                  height: '6vw',
                  marginRight: '1vw',
                  marginTop: '-0.9vw',
                }}
              />
            }
            style={{
              backgroundColor: 'var(--successLight)',
              color: 'var(--successBase)',
            }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
