import { type Dayjs } from 'dayjs';

import { type DateRangeOption } from '~/ui/molecules/DateInput';

import { dayjs } from './datetime';

export type FilterContext = {
  component?: 'filterGroup' | 'filterModel' | 'query';
  isCustomField: boolean;
  page?: 'dashboard' | 'delivery' | 'home';
};

export type FilterModelItem = {
  field: string;
  id: number;
  operator: string;
  value: string;
};

export type FilterModel = {
  items: FilterModelItem[];
  logicOperator: 'and' | 'or';
};

/**
 * Returns an object with context information where a filter is applied by merging default values with provided partial context.
 *
 * @param {Partial<FilterContext>} filterContext - Partial filter context to merge with defaults
 * @returns {FilterContext} Complete filter context with all required fields
 */
export const getFilterContext = (
  filterContext: Partial<FilterContext>,
): FilterContext => ({
  component: undefined,
  isCustomField: false,
  page: undefined,
  ...filterContext,
});

/**
 * Returns an array of two dates representing the start and end dates for a predefined date range.
 *
 * @param {DateRangeOption} predefinedDateRange - The predefined date range option (e.g., 'day', 'week', 'month')
 * @param {'date' | 'string'} [outputAs='date'] - The desired output format for the dates
 * @returns {[date, date] | [string, string]} An array of two dates in either Date object or ISO string format
 */
export const getDatesForPredefinedDateRange = <
  T extends 'date' | 'string' = 'string',
>(
  predefinedDateRange: DateRangeOption,
  outputAs: T = 'date' as T,
): T extends 'date' ? Date[] : string[] => {
  const today = dayjs();

  let dates: [Dayjs, Dayjs] = [today, today];

  switch (predefinedDateRange) {
    case 'day': {
      dates = [today, today];
      break;
    }

    case 'yesterday': {
      const yesterday = today.subtract(1, 'day');
      dates = [yesterday, yesterday];
      break;
    }

    case 'week': {
      const startOfWeek = today.startOf('week');
      dates = [startOfWeek, today];
      break;
    }

    case 'last-week': {
      const lastWeek = today.subtract(1, 'week');
      dates = [lastWeek.startOf('week'), lastWeek.endOf('week')];
      break;
    }

    case 'month': {
      const from = today.startOf('month');
      dates = [from, today];
      break;
    }

    case 'last-month': {
      const lastMonth = today.subtract(1, 'month');
      dates = [lastMonth.startOf('month'), lastMonth.endOf('month')];
      break;
    }

    case 'year': {
      const from = today.startOf('year');
      dates = [from, today];
      break;
    }

    case '7-days': {
      // today is included in 'last 7 days' date range -> start 7 - 1 = 6 days prior
      const from = today.subtract(6, 'day');
      dates = [from, today];
      break;
    }

    case '30-days': {
      // today is included in 'last 30 days' date range -> start 30 - 1 = 29 days prior
      const from = today.subtract(29, 'day');
      dates = [from, today];
      break;
    }

    case '365-days': {
      // today is included in 'last 365 days' date range -> start 365 - 1 = 364 days prior
      const from = today.subtract(364, 'day');
      dates = [from, today];
      break;
    }
  }

  return (
    outputAs === 'date'
      ? dates.map((date) => date.toDate())
      : dates.map((date) => date.toISOString())
  ) as T extends 'date' ? Date[] : string[];
};
