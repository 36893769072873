import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/logging';

import { queryKeysCustomField } from './queryKeys';

export type CustomFieldOption = {
  customerValue: string;
  description: string;
  displayName: string;
  id: UUID;
  name: string;
};

/**
 * Fetches a given custom field option of a given custom field from the API.
 * @param customFieldId - The ID of the custom field
 * @param customFieldOptionId - The ID of the custom field option
 * @returns The custom field option data
 * @throws Error if the API request fails
 * @see https://app.dev.vestigas.com/redoc#tag/Custom-Field/operation/get_custom_field_option_by_id_custom_field__custom_field_id__option__custom_field_option_id__get
 */
export const fetchCustomFieldOption = async (
  customFieldId: UUID,
  customFieldOptionId: UUID,
): Promise<CustomFieldOption> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.CUSTOM_FIELD.GET_OPTION(customFieldId, customFieldOptionId))
      .json<CustomFieldOption>();

    return response;
  } catch (error) {
    Log.error('Error fetching CustomField option', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting a given custom field option of a given custom field.
 *
 * @param customFieldId - The ID of the custom field
 * @param customFieldOptionId - The ID of the custom field option
 * @param options - Additional options for the useQuery hook
 * @returns The result of the useQuery hook containing the custom field option
 */
export const useQueryCustomFieldOption = (
  customFieldId: UUID,
  customFieldOptionId: UUID,
  options?: Omit<UseQueryOptions<CustomFieldOption>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: async () =>
      fetchCustomFieldOption(customFieldId, customFieldOptionId),
    queryKey: queryKeysCustomField.getOption(
      customFieldId,
      customFieldOptionId,
    ),
    enabled: Boolean(customFieldId && customFieldOptionId),
    ...options,
  });
