import { type UncefactUnitType, UNITS } from '~/constants/units';

import { sortByKey } from '~/utils/array';

import { i18n } from '~/utils';

export const MAXIMUM_NUMBER_OF_ARTICLES_DISPLAYED = 100;
export const MAXIMUM_NUMBER_OF_LEGEND_ITEMS = 6;

export const selectableUnits = [
  {
    label: i18n.t('mostImportantUnits.label', { ns: 'units' }),
    options: Object.entries(UNITS)
      .filter(([key]) => ['EA', 'TNE', 'KGM'].includes(key))
      .map(([key, details]) => ({
        value: key as UncefactUnitType,
        label: details.labelLong,
      })),
  },
  {
    label: i18n.t('otherUnits.label', { ns: 'units' }),
    options: sortByKey(
      Object.entries(UNITS)
        .filter(([key]) =>
          [
            'MTR',
            'MTK',
            'SA',
            'RO',
            'PF',
            'PA',
            'BJ',
            'BG',
            'PK',
            'TU',
            'CA',
            'CI',
            'PR',
            'CT',
            'E48',
            'MTQ',
            'LTR',
          ].includes(key),
        )
        .map(([key, details]) => ({
          value: key as UncefactUnitType,
          label: details.labelLong,
        })),
      'label',
    ),
  },
];
