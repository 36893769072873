import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { LOADING_STATE } from '~/constants/LoadingState';
import { TAB } from '~/constants/Tab';

import { camelcaseKeysFromApi } from '~/services/kyClient';
import LocalStorageService from '~/services/localStorage.service';

import DatagridUtils from '~/utils/datagridUtils';
import { dateUtils } from '~/utils/dateUtils';
import { getDatesForPredefinedDateRange } from '~/utils/filters';
import Log from '~/utils/logging';

import {
  changesFilterGroup,
  unassignedDeliveryNotesFilterGroup,
} from '~/components/deliveries/deliveryOverview/DeliveryFilterGroups/utils/filterGroups/fixTabsOrder';

const filtersSlice = createSlice({
  initialState: {
    dashboard_filterGroups:
      LocalStorageService.getObjectFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_FILTER_GROUPS,
      ) ?? [],

    dashboard_filterRows:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_FILTER_ROWS,
      ) ?? [],

    // comes from this.PREDEFINED_DATE_RANGE_OPTIONS.MONTH.ID
    dashboard_individualDateRange:
      LocalStorageService.getBooleanFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_INDIVIDUAL_DATE_RANGE,
      ),

    dashboard_selectableColumns: [],
    dashboard_selectedColumns: [],

    // If no filter group is currently selected, only "Geliefert" dlns should be displayed in the Dashboard
    dashboard_selectedAcceptState:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_ACCEPT_STATE,
      ) ?? [],

    dashboard_selectedArticle:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_ARTICLE,
      ) ?? [],

    dashboard_selectedArticleNumber:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_ARTICLE_NUMBER,
      ) ?? [],

    dashboard_selectedCategory:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_CATEGORY,
      ) ?? [],

    dashboard_selectedCostCenter:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_COST_CENTER,
      ) ?? [],

    dashboard_selectedCustomFields:
      LocalStorageService.getObjectFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_CUSTOM_FIELDS,
      ) ?? [],

    dashboard_selectedDateRange: dateUtils.getDateRangeFromCookies(
      LocalStorageService.FILTER_DASHBOARD_DATE_RANGE,
      ...getDatesForPredefinedDateRange('month'),
    ),

    dashboard_selectedFilterGroup:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SELECTED_FILTER_GROUP,
      ) ?? null,

    dashboard_selectedFromSite:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_FROM_SITE,
      ) ?? [],

    dashboard_selectedOuId:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_OU_ID,
      ) ?? [],

    dashboard_selectedPermittedCostCenters:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_PERMITTED_COST_CENTERS,
      ) ?? [],

    dashboard_selectedPermittedToSites:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_PERMITTED_TO_SITES,
      ) ?? [],

    dashboard_selectedPredefinedDateRange:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_PREDEFINED_DATE_RANGE,
      ) ?? 'month',

    dashboard_selectedProcessState:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_PROCESS_STATE,
      ) ?? [],

    dashboard_selectedRecipient:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_RECIPIENT,
      ) ?? [],

    dashboard_selectedSettledStatus:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SETTLED_STATUS,
      ) ?? [],

    dashboard_selectedSupplier:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SUPPLIER,
      ) ?? [],

    dashboard_selectedToSiteRecipient:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SITE_RECIPIENT,
      ) ?? [],

    dashboard_selectedToSiteSupplier:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SITE_SUPPLIER,
      ) ?? [],

    dashboard_selectedToSiteSupplierTradeContact:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SITE_SUPPLIER_TRADE_CONTACT,
      ) ?? [],

    dashboard_selectedUnit:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_UNIT,
      ) ?? 'EA',

    // filters of dashboard component
    dashboard_tab: TAB.DASHBOARD.CHARTS.INDEX,

    // filters of user metrics
    // The entire UserMetrics feature can not be implemented with the current paginated API endpoints.
    // Also, nobody uses it.
    // userMetrics_dateRange: dateUtils.getDateRangeFromCookies(
    //   LocalStorageService.FILTER_USER_METRICS_DATE_RANGE,
    //   new Date().setMonth(new Date().getMonth() - 6),
    //   new Date(),
    // ),
    // userMetrics_predefinedDateRange:
    //   LocalStorageService.getLocalStorage(
    //     LocalStorageService.FILTER_USER_METRICS_PREDEFINED_DATE_RANGE,
    //   ) ?? 'individual', // comes from this.PREDEFINED_DATE_RANGE_OPTIONS.MONTH.ID
    // userMetrics_individualDateRange:
    //   LocalStorageService.getBooleanFromLocalStorage(
    //     LocalStorageService.FILTER_USER_METRICS_INDIVIDUAL_DATE_RANGE,
    //     true,
    //   ),
    // userMetrics_sortModel: [
    //   {
    //     field: 'assignedDlnsCount',
    //     sort: 'desc',
    //   },
    // ],
    dataExchanges_query: '',

    dataExchanges_selectedCompany:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_DATA_EXCHANGES_COMPANY,
      ) ?? null,

    deliveries: {
      changes: {
        sortModel: [],
      },
      dateRange: {
        isCustom: LocalStorageService.getBooleanFromLocalStorage(
          LocalStorageService.FILTER_DELIVERY_INDIVIDUAL_DATE_RANGE,
          true,
        ),
        predefined:
          LocalStorageService.getLocalStorage(
            LocalStorageService.FILTER_DELIVERY_PREDEFINED_DATE_RANGE,
          ) ?? 'month',
      },
      filterGroups:
        LocalStorageService.getObjectFromLocalStorage(
          LocalStorageService.FILTER_DELIVERY_FILTER_GROUPS,
        ) ?? [],

      filterRows:
        LocalStorageService.getArrayFromLocalStorage(
          LocalStorageService.FILTER_DELIVERY_FILTER_ROWS,
        ) ?? [],
      selected: {
        filter: {
          acceptStates:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_ACCEPT_STATE,
            ) ?? [],
          articleNumbers:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_ARTICLE_NUMBER,
            ) ?? [],
          articles:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_ARTICLE,
            ) ?? [],
          costCenters:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_COST_CENTER,
            ) ?? [],
          customFields:
            LocalStorageService.getObjectFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_CUSTOM_FIELDS,
            ) ?? [],
          dateRange: dateUtils.getDateRangeFromCookies(
            LocalStorageService.FILTER_DELIVERY_DATE_RANGE,
            ...getDatesForPredefinedDateRange('month'),
          ),
          fromSites:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_FROM_SITE,
            ) ?? [],
          permittedCostCenters:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_PERMITTED_COST_CENTERS,
            ) ?? [],
          permittedToSites:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_PERMITTED_TO_SITES,
            ) ?? [],
          processStates:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_PROCESS_STATE,
            ) ?? [],
          query: '',
          recipients:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_RECIPIENT,
            ) ?? [],
          settledStatuses:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_SETTLED_STATUS,
            ) ?? [],
          suppliers:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_SUPPLIER,
            ) ?? [],
          toSiteRecipients:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_SITE_RECIPIENT,
            ) ?? [],
          toSiteSuppliers:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.FILTER_DELIVERY_SITE_SUPPLIER,
            ) ?? [],
        },
        filterGroup: {
          id:
            LocalStorageService.getLocalStorage(
              LocalStorageService.DELIVERY_SELECTED_FILTER_GROUP,
            ) ?? '',
          isOpen: LocalStorageService.getBooleanFromLocalStorage(
            LocalStorageService.FILTER_DELIVERY_FILTER_GROUP_OPEN,
            false,
          ),
        },
        sortModel: [
          {
            field: 'createdOn',
            sort: 'desc',
          },
        ],
      },
      selectField: 'all',
    },

    delivery_dateRange: dateUtils.getDateRangeFromCookies(
      LocalStorageService.FILTER_DELIVERY_DATE_RANGE,
      ...getDatesForPredefinedDateRange('month'),
    ),

    delivery_filterGroupOpen: LocalStorageService.getBooleanFromLocalStorage(
      LocalStorageService.FILTER_DELIVERY_FILTER_GROUP_OPEN,
      false,
    ),

    delivery_filterGroups:
      LocalStorageService.getObjectFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_FILTER_GROUPS,
      ) ?? [],

    delivery_filterRows:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_FILTER_ROWS,
      ) ?? [],

    delivery_individualDateRange:
      LocalStorageService.getBooleanFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_INDIVIDUAL_DATE_RANGE,
        false,
      ),

    delivery_predefinedDateRange:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_DELIVERY_PREDEFINED_DATE_RANGE,
      ) ?? 'month',

    delivery_query: '',

    delivery_selectedAcceptState:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_ACCEPT_STATE,
      ) ?? [],

    delivery_selectedArticle:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_ARTICLE,
      ) ?? [],

    delivery_selectedArticleNumber:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_ARTICLE_NUMBER,
      ) ?? [],

    delivery_selectedCategory:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_CATEGORY,
      ) ?? [],

    delivery_selectedCostCenter:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_COST_CENTER,
      ) ?? [],

    delivery_selectedCustomFields:
      LocalStorageService.getObjectFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_CUSTOM_FIELDS,
      ) ?? [],

    delivery_selectedFilterGroup:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SELECTED_FILTER_GROUP,
      ) ?? null,

    delivery_selectedFromSite:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_FROM_SITE,
      ) ?? [],

    delivery_selectedNumber:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_NUMBER,
      ) ?? [],

    delivery_selectedPermittedCostCenters:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_PERMITTED_COST_CENTERS,
      ) ?? [],

    delivery_selectedPermittedToSites:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_PERMITTED_TO_SITES,
      ) ?? [],

    delivery_selectedProcessState:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_PROCESS_STATE,
      ) ?? [],

    delivery_selectedRecipient:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_RECIPIENT,
      ) ?? [],

    delivery_selectedSettledStatus:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SETTLED_STATUS,
      ) ?? [],

    delivery_selectedSupplier:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SUPPLIER,
      ) ?? [],

    delivery_selectedToSiteRecipient:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SITE_RECIPIENT,
      ) ?? [],

    delivery_selectedToSiteSupplier:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SITE_SUPPLIER,
      ) ?? [],

    delivery_selectedOuId:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_FILTER_OU_ID,
      ) ?? [],

    delivery_selectField: 'all',

    // filters of delivery overview component
    delivery_tab:
      LocalStorageService.getNumberFromLocalStorage(
        LocalStorageService.FILTER_DELIVERY_TAB,
      ) ?? TAB.DELIVERY.LIST.INDEX,

    // pagination of delivery list component
    deliveryList_page: 0,

    deliveryList_sortModel: [
      {
        field: 'createdOn',
        sort: 'desc',
      },
    ],

    global: {
      selected: {
        filter: {
          costCenters:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.SELECTED_COST_CENTERS,
            ) ?? [],
          sites:
            LocalStorageService.getArrayFromLocalStorage(
              LocalStorageService.SELECTED_SITES,
            ) ?? [],
        },
      },
    },

    home_individualDateRange: LocalStorageService.getBooleanFromLocalStorage(
      LocalStorageService.FILTER_HOME_INDIVIDUAL_DATE_RANGE,
    ),

    home_selectedDateRange: dateUtils.getDateRangeFromCookies(
      LocalStorageService.FILTER_HOME_DATE_RANGE,
      ...getDatesForPredefinedDateRange('month'),
    ),

    home_selectedPredefinedDateRange:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_HOME_PREDEFINED_DATE_RANGE,
      ) ?? 'month',

    // filters of home screen
    home_selectedUnit: null,

    invoice_dateRange: dateUtils.getDateRangeFromCookies(
      LocalStorageService.FILTER_INVOICE_DATE_RANGE,
      ...getDatesForPredefinedDateRange('month'),
    ),

    invoice_filterGroupOpen: LocalStorageService.getBooleanFromLocalStorage(
      LocalStorageService.FILTER_INVOICE_FILTER_GROUP_OPEN,
      false,
    ),

    invoice_filterGroups:
      LocalStorageService.getObjectFromLocalStorage(
        LocalStorageService.FILTER_INVOICE_FILTER_GROUPS,
      ) ?? [],

    invoice_filterModel: DatagridUtils.EMPTY_FILTER_MODEL,

    invoice_filterRows:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_INVOICE_FILTER_ROWS,
      ) ?? [],

    invoice_individualDateRange: LocalStorageService.getBooleanFromLocalStorage(
      LocalStorageService.FILTER_INVOICE_INDIVIDUAL_DATE_RANGE,
      true,
    ),

    invoice_predefinedDateRange:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_INVOICE_PREDEFINED_DATE_RANGE,
      ) ?? 'individual',

    invoice_query: '',

    invoice_selectedBuyer:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_INVOICE_BUYER,
      ) ?? [],

    invoice_selectedFilterGroup:
      LocalStorageService.getLocalStorage(
        LocalStorageService.FILTER_INVOICE_SELECTED_FILTER_GROUP,
      ) ?? null,

    invoice_selectedNumber:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_INVOICE_NUMBER,
      ) ?? [],

    invoice_selectedSeller:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_INVOICE_SELLER,
      ) ?? [],

    // filters of invoice overview component
    invoice_selectedStatus:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_INVOICE_STATUS,
      ) ?? [],

    invoice_selectedToSite:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_INVOICE_TO_SITE,
      ) ?? [],

    invoice_selectField: 'all',

    invoice_sortModel: [
      {
        field: 'date', // value isn't taken from Invoice.PROPERTY.DATE because importing Invoice model leads to circular dependency in Jest
        sort: 'desc',
      },
    ],

    // date of the oldest filtered delivery note in the webapp
    oldestFilteredDlnDate: null,

    oldestFilteredDlnDateLoading: LOADING_STATE.LOADING,

    selectedCostCenters:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.SELECTED_COST_CENTERS,
      ) ?? [],

    // sites and cost centers selection
    selectedSites:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.SELECTED_SITES,
      ) ?? [],

    suppliers_responsible_people:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DATA_SUPPLIERS_RESPONSIBLE_PEOPLE,
      ) ?? [],

    // new suppliers data store
    suppliers_selectedCompanies:
      LocalStorageService.getArrayFromLocalStorage(
        LocalStorageService.FILTER_DATA_SELECTED_SUPPLIERS_COMPANY,
      ) ?? [],
    suppliers_sort_model: LocalStorageService.getObjectFromLocalStorage(
      LocalStorageService.FILTER_DATA_SUPPLIERS_SORT_MODEL,
    ) ?? {
      sortDirection: 'ASC',
      sortKey: 'alphabetically',
    },
  },
  name: 'filters',
  reducers: {
    /**
     * Updates the selected filter group for the provided page.
     *
     * @param {Object} state - The current state object.
     * @param {Object} payload - The payload object containing page, groupId, and isOpen.
     * @param {string} payload.page - The page to update the filter group for.
     * @param {string} payload.groupId - The ID of the filter group to set.
     * @param {boolean} payload.isOpen - The open state of the filter group.
     * @return {void} No return value.
     */
    selectFilterGroup(state, { payload: { groupId, isOpen = true, page } }) {
      if (!state[page] || groupId === undefined) {
        Log.error(
          `Invalid selectFilterGroup payload: ${page}/${groupId} (isOpen: ${isOpen})`,
        );
        return;
      }

      state[page].selected.filterGroup.id = groupId;
      state[page].selected.filterGroup.isOpen =
        groupId === null ? false : isOpen;

      // TODO: handle updating the selected filter values based on the new filter group
    },

    setDashboard_filterGroups(state, parameter) {
      const payload = camelcaseKeysFromApi(parameter.payload);

      state.dashboard_filterGroups = payload;
      LocalStorageService.setObjectAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_FILTER_GROUPS,
        payload,
      );
    },

    setDashboard_filterRows(state, parameter) {
      const { payload } = parameter;
      state.dashboard_filterRows = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_FILTER_ROWS,
        payload,
      );
    },

    setDashboard_individualDateRange(state, parameter) {
      const { payload } = parameter;
      state.dashboard_individualDateRange = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_INDIVIDUAL_DATE_RANGE,
        payload,
      );
    },

    setDashboard_selectableColumns(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectableColumns = payload;
      LocalStorageService.setObjectAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SELECTABLE_COLUMNS,
        payload,
      );
    },

    setDashboard_selectedAcceptState(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedAcceptState = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_ACCEPT_STATE,
        payload,
      );
    },

    setDashboard_selectedArticle(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedArticle = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_ARTICLE,
        payload,
      );
    },

    setDashboard_selectedArticleNumber(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedArticleNumber = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_ARTICLE_NUMBER,
        payload,
      );
    },

    setDashboard_selectedCategory(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedCategory = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_CATEGORY,
        payload,
      );
    },

    setDashboard_selectedColumns(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedColumns = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SELECTED_COLUMNS,
        payload,
      );
    },

    setDashboard_selectedCostCenter(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedCostCenter = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_COST_CENTER,
        payload,
      );
    },

    setDashboard_selectedCustomFields(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedCustomFields = payload;
      LocalStorageService.setObjectAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_CUSTOM_FIELDS,
        payload,
      );
    },

    setDashboard_selectedDateRange(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedDateRange = payload;
      dateUtils.setDateRangeAsCookie(
        LocalStorageService.FILTER_DASHBOARD_DATE_RANGE,
        payload,
      );
    },

    setDashboard_selectedFilterGroup(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedFilterGroup = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SELECTED_FILTER_GROUP,
        payload,
      );
    },

    setDashboard_selectedFromSite(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedFromSite = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_FROM_SITE,
        payload,
      );
    },

    setDashboard_selectedOuId(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedOuId = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_OU_ID,
        payload,
      );
    },

    setDashboard_selectedPermittedCostCenters(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedPermittedCostCenters = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_PERMITTED_COST_CENTERS,
        payload,
      );
    },

    setDashboard_selectedPermittedToSites(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedPermittedToSites = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_PERMITTED_TO_SITES,
        payload,
      );
    },

    setDashboard_selectedPredefinedDateRange(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedPredefinedDateRange = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_PREDEFINED_DATE_RANGE,
        payload,
      );
    },

    setDashboard_selectedProcessState(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedProcessState = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_PROCESS_STATE,
        payload,
      );
    },

    setDashboard_selectedRecipient(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedRecipient = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_RECIPIENT,
        payload,
      );
    },

    setDashboard_selectedSettledStatus(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedSettledStatus = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SETTLED_STATUS,
        payload,
      );
    },

    setDashboard_selectedSupplier(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedSupplier = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SUPPLIER,
        payload,
      );
    },

    setDashboard_selectedToSiteRecipient(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedToSiteRecipient = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SITE_RECIPIENT,
        payload,
      );
    },

    setDashboard_selectedToSiteSupplier(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedToSiteSupplier = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SITE_SUPPLIER,
        payload,
      );
    },

    setDashboard_selectedToSiteSupplierTradeContact(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedToSiteSupplierTradeContact = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_SITE_SUPPLIER_TRADE_CONTACT,
        payload,
      );
    },

    setDashboard_selectedUnit(state, parameter) {
      const { payload } = parameter;
      state.dashboard_selectedUnit = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DASHBOARD_UNIT,
        payload,
      );
    },

    // actions of dashboard overview component
    setDashboard_tab(state, parameter) {
      const { payload } = parameter;
      state.dashboard_tab = payload;
    },

    // actions of user metrics component
    // The entire UserMetrics feature can not be implemented with the current paginated API endpoints.
    // Also, nobody uses it.
    // setUserMetrics_dateRange(state, parameter) {
    //   const { payload } = parameter;
    //   state.userMetrics_dateRange = payload;
    //   dateUtils.setDateRangeAsCookie(
    //     LocalStorageService.FILTER_USER_METRICS_DATE_RANGE,
    //     payload,
    //   );
    // },
    // setUserMetrics_predefinedDateRange(state, parameter) {
    //   const { payload } = parameter;
    //   state.userMetrics_predefinedDateRange = payload;
    //   LocalStorageService.setLocalStorage(
    //     LocalStorageService.FILTER_USER_METRICS_PREDEFINED_DATE_RANGE,
    //     payload,
    //   );
    // },
    // setUserMetrics_individualDateRange(state, parameter) {
    //   const { payload } = parameter;
    //   state.userMetrics_individualDateRange = payload;
    //   LocalStorageService.setLocalStorage(
    //     LocalStorageService.FILTER_USER_METRICS_INDIVIDUAL_DATE_RANGE,
    //     payload,
    //   );
    // },
    // setUserMetrics_sortModel(state, parameter) {
    //   const { payload } = parameter;
    //   state.userMetrics_sortModel = payload;
    // },
    setDataExchanges_query(state, parameter) {
      const { payload } = parameter;
      state.dataExchanges_query = payload;
    },

    setDataExchanges_selectedCompany(state, parameter) {
      const { payload } = parameter;
      state.dataExchanges_selectedCompany = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DATA_EXCHANGES_COMPANY,
        payload,
      );
    },

    setDelivery_dateRange(state, parameter) {
      const { payload } = parameter;
      if (!payload.dateRange) {
        Log.error('Invalid date range: ' + payload.dateRange);
      }

      state.delivery_dateRange = payload.dateRange;
      if (!payload.updateCookie) {
        return;
      }

      dateUtils.setDateRangeAsCookie(
        LocalStorageService.FILTER_DELIVERY_DATE_RANGE,
        payload.dateRange,
      );
    },

    setDelivery_filterGroupOpen(state, parameter) {
      const { payload } = parameter;
      state.delivery_filterGroupOpen = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DELIVERY_FILTER_GROUP_OPEN,
        payload,
      );
    },

    setDelivery_filterGroups(state, parameter) {
      const payload = camelcaseKeysFromApi(parameter.payload).map(
        ({ id, ...rest }) => ({
          ...rest,
          id: id ?? uuidv4(), // make sure every filter group has an id
        }),
      );

      // Remove changesFilterGroup if it exists and always insert it as second filter group
      const changesFilterGroupIndex = payload.findIndex(
        ({ name }) =>
          name.toLowerCase() === changesFilterGroup.name.toLowerCase(),
      );
      if (changesFilterGroupIndex !== -1) {
        payload.splice(changesFilterGroupIndex, 1);
      }

      payload.splice(1, 0, changesFilterGroup);

      // Remove unassignedDeliveryNotesFilterGroup if it exists (by comparing filter group names) and insert unassignedDeliveryNotesFilterGroup as third filter group, if it existed
      const unassignedDeliveryNotesFilterGroupIndex = payload.findIndex(
        ({ name }) =>
          name.toLowerCase() ===
          unassignedDeliveryNotesFilterGroup.name.toLowerCase(),
      );
      if (unassignedDeliveryNotesFilterGroupIndex !== -1) {
        payload.splice(unassignedDeliveryNotesFilterGroupIndex, 1);
        payload.splice(2, 0, unassignedDeliveryNotesFilterGroup);
      }

      state.delivery_filterGroups = payload;

      LocalStorageService.setObjectAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_FILTER_GROUPS,
        payload,
      );
    },

    setDelivery_filterRows(state, parameter) {
      const { payload } = parameter;
      state.delivery_filterRows = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_FILTER_ROWS,
        payload,
      );
    },

    setDelivery_individualDateRange(state, parameter) {
      const { payload } = parameter;
      state.delivery_individualDateRange = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DELIVERY_INDIVIDUAL_DATE_RANGE,
        payload,
      );
    },

    setDelivery_predefinedDateRange(state, parameter) {
      const { payload } = parameter;
      state.delivery_predefinedDateRange = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DELIVERY_PREDEFINED_DATE_RANGE,
        payload,
      );
    },

    setDelivery_query(state, parameter) {
      const { payload } = parameter;
      state.delivery_query = payload;
    },

    setDelivery_selectedAcceptState(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedAcceptState = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_ACCEPT_STATE,
        payload,
      );
    },

    setDelivery_selectedArticle(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedArticle = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_ARTICLE,
        payload,
      );
    },

    setDelivery_selectedArticleNumber(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedArticleNumber = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_ARTICLE_NUMBER,
        payload,
      );
    },

    setDelivery_selectedCategory(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedCategory = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_CATEGORY,
        payload,
      );
    },

    setDelivery_selectedCostCenter(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedCostCenter = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_COST_CENTER,
        payload,
      );
    },

    setDelivery_selectedCustomFields(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedCustomFields = payload;
      LocalStorageService.setObjectAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_CUSTOM_FIELDS,
        payload,
      );
    },

    setDelivery_selectedFilterGroup(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedFilterGroup = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SELECTED_FILTER_GROUP,
        payload,
      );
    },

    setDelivery_selectedFromSite(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedFromSite = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_FROM_SITE,
        payload,
      );
    },

    setDelivery_selectedNumber(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedNumber = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_NUMBER,
        payload,
      );
    },

    setDelivery_selectedOuId(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedOuId = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_OU_ID,
        payload,
      );
    },

    setDelivery_selectedPermittedCostCenters(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedPermittedCostCenters = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_PERMITTED_COST_CENTERS,
        payload,
      );
    },

    setDelivery_selectedPermittedToSites(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedPermittedToSites = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_PERMITTED_TO_SITES,
        payload,
      );
    },

    setDelivery_selectedProcessState(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedProcessState = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_PROCESS_STATE,
        payload,
      );
    },

    setDelivery_selectedRecipient(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedRecipient = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_RECIPIENT,
        payload,
      );
    },

    setDelivery_selectedSettledStatus(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedSettledStatus = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SETTLED_STATUS,
        payload,
      );
    },

    setDelivery_selectedSupplier(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedSupplier = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SUPPLIER,
        payload,
      );
    },

    setDelivery_selectedToSiteRecipient(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedToSiteRecipient = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SITE_RECIPIENT,
        payload,
      );
    },

    setDelivery_selectedToSiteSupplier(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectedToSiteSupplier = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DELIVERY_SITE_SUPPLIER,
        payload,
      );
    },

    setDelivery_selectField(state, parameter) {
      const { payload } = parameter;
      state.delivery_selectField = payload;
    },

    // actions of delivery overview component
    setDelivery_tab(state, parameter) {
      const { payload } = parameter;
      state.delivery_tab = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_DELIVERY_TAB,
        payload,
      );
    },

    // actions of delivery list pagination
    setDeliveryList_page(state, parameter) {
      const { payload } = parameter;
      state.deliveryList_page = payload;
    },

    setDeliveryList_sortModel(state, parameter) {
      const { payload } = parameter;
      state.deliveryList_sortModel = payload;
    },

    // -----------------------------------------------------------------------------------------------------------------
    /**
     * Updates the filter value for the provided page, filter key, and filter value.
     *
     * @example: setFilterValue({ { page: 'deliveries', filterKey: 'selectedUnit', filterValue: 'm3' } })
     *
     * @param {Object} state - The current state object.
     * @param {string} payload.page - The page to update the filter value for: 'deliveries' | 'dashboard' | 'home' | 'userMetrics'.
     * @param {string} payload.filterKey - The key representing the filter, e.g. articleNumbers, suppliers
     * @param {any} payload.filterValue - The new value for the filter. Replaces the old value.
     * @param {Object} payload.options - Additional options you want to pass to the reducer.
     * @return {void} No return value.
     */
    setFilterValue(
      state,
      { payload: { filterKey, filterValue, options = {}, page } },
    ) {
      if (
        !state[page]?.selected?.filter ||
        state[page].selected.filter[filterKey] === undefined
      ) {
        Log.error(
          `Invalid setFilterValue payload: ${page}/${filterKey} -> ${filterValue}`,
        );
        return;
      }

      // Set the new value(s) to the selected filter.
      state[page].selected.filter[filterKey] = filterValue;
    },

    setHome_individualDateRange(state, parameter) {
      const { payload } = parameter;
      state.home_individualDateRange = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_HOME_INDIVIDUAL_DATE_RANGE,
        payload,
      );
    },

    setHome_selectedDateRange(state, parameter) {
      const { payload } = parameter;
      state.home_selectedDateRange = payload;
      dateUtils.setDateRangeAsCookie(
        LocalStorageService.FILTER_HOME_DATE_RANGE,
        payload,
      );
    },

    setHome_selectedPredefinedDateRange(state, parameter) {
      const { payload } = parameter;
      state.home_selectedPredefinedDateRange = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_HOME_PREDEFINED_DATE_RANGE,
        payload,
      );
    },

    // actions of home component
    setHome_selectedUnit(state, parameter) {
      const { payload } = parameter;
      state.home_selectedUnit = payload;
    },

    setInvoice_dateRange(state, parameter) {
      const { payload } = parameter;
      if (!payload.dateRange) {
        Log.error('Invalid date range: ' + payload.dateRange);
      }

      state.invoice_dateRange = payload.dateRange;
      if (!payload.updateCookie) {
        return;
      }

      dateUtils.setDateRangeAsCookie(
        LocalStorageService.FILTER_INVOICE_DATE_RANGE,
        payload.dateRange,
      );
    },

    setInvoice_filterGroupOpen(state, parameter) {
      const { payload } = parameter;
      state.invoice_filterGroupOpen = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_INVOICE_FILTER_GROUP_OPEN,
        payload,
      );
    },

    setInvoice_filterGroups(state, parameter) {
      const payload = camelcaseKeysFromApi(parameter.payload);

      state.invoice_filterGroups = payload;
      LocalStorageService.setObjectAsLocalStorage(
        LocalStorageService.FILTER_INVOICE_FILTER_GROUPS,
        payload,
      );
    },

    setInvoice_filterModel(state, parameter) {
      const { payload } = parameter;
      state.invoice_filterModel = payload;
    },

    setInvoice_filterRows(state, parameter) {
      const { payload } = parameter;
      state.invoice_filterRows = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_INVOICE_FILTER_ROWS,
        payload,
      );
    },

    setInvoice_individualDateRange(state, parameter) {
      const { payload } = parameter;
      state.invoice_individualDateRange = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_INVOICE_INDIVIDUAL_DATE_RANGE,
        payload,
      );
    },

    setInvoice_predefinedDateRange(state, parameter) {
      const { payload } = parameter;
      state.invoice_predefinedDateRange = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_INVOICE_PREDEFINED_DATE_RANGE,
        payload,
      );
    },

    setInvoice_query(state, parameter) {
      const { payload } = parameter;
      state.invoice_query = payload;
    },

    setInvoice_selectedBuyer(state, parameter) {
      const { payload } = parameter;
      state.invoice_selectedBuyer = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_INVOICE_BUYER,
        payload,
      );
    },

    setInvoice_selectedFilterGroup(state, parameter) {
      const { payload } = parameter;
      state.invoice_selectedFilterGroup = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.FILTER_INVOICE_SELECTED_FILTER_GROUP,
        payload,
      );
    },

    setInvoice_selectedNumber(state, parameter) {
      const { payload } = parameter;
      state.invoice_selectedNumber = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_INVOICE_NUMBER,
        payload,
      );
    },

    setInvoice_selectedSeller(state, parameter) {
      const { payload } = parameter;
      state.invoice_selectedSeller = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_INVOICE_SELLER,
        payload,
      );
    },

    // actions of invoice overview component
    setInvoice_selectedStatus(state, parameter) {
      const { payload } = parameter;
      state.invoice_selectedStatus = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_INVOICE_STATUS,
        payload,
      );
    },

    setInvoice_selectedToSite(state, parameter) {
      const { payload } = parameter;
      state.invoice_selectedToSite = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_INVOICE_TO_SITE,
        payload,
      );
    },

    setInvoice_selectField(state, parameter) {
      const { payload } = parameter;
      state.invoice_selectField = payload;
    },

    setInvoice_sortModel(state, parameter) {
      const { payload } = parameter;
      state.invoice_sortModel = payload;
    },

    setOldestFilteredDlnDate(state, parameter) {
      const { payload } = parameter;
      state.oldestFilteredDlnDate = payload;
      state.oldestFilteredDlnDateLoading = LOADING_STATE.SUCCEEDED;
    },

    setOldestFilteredDlnDateLoading(state, parameter) {
      const { payload } = parameter;
      state.oldestFilteredDlnDateLoading = payload;
    },

    setSelectedCostCenters(state, parameter) {
      const { payload } = parameter;
      state.selectedCostCenters = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.SELECTED_COST_CENTERS,
        payload,
      );
    },

    // -----------------------------------------------------------------------------------------------------------------
    // actions of sites and cost centers selection
    setSelectedSites(state, parameter) {
      const { payload } = parameter;
      state.selectedSites = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.SELECTED_SITES,
        payload,
      );
    },

    setSuppliers_responsiblePeople(state, parameter) {
      const { payload } = parameter;
      state.suppliers_responsible_people = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DATA_SUPPLIERS_RESPONSIBLE_PEOPLE,
        payload,
      );
    },

    setSuppliers_selectCompany(state, parameter) {
      const { payload } = parameter;
      state.suppliers_selectedCompanies = payload;
      LocalStorageService.setArrayAsLocalStorage(
        LocalStorageService.FILTER_DATA_SELECTED_SUPPLIERS_COMPANY,
        payload,
      );
    },
    setSuppliers_sortModel(state, parameter) {
      const { payload } = parameter;
      state.suppliers_sort_model = payload;
      LocalStorageService.setObjectAsLocalStorage(
        LocalStorageService.FILTER_DATA_SUPPLIERS_SORT_MODEL,
        payload,
      );
    },
  },
});

const { actions, reducer } = filtersSlice;
export const {
  selectFilterGroup,
  setDashboard_filterGroups,
  setDashboard_filterRows,
  setDashboard_individualDateRange,
  setDashboard_selectableColumns,
  setDashboard_selectedAcceptState,
  setDashboard_selectedArticle,
  setDashboard_selectedArticleNumber,
  setDashboard_selectedCategory,
  setDashboard_selectedColumns,
  setDashboard_selectedCostCenter,
  setDashboard_selectedCustomFields,
  setDashboard_selectedDateRange,
  setDashboard_selectedFilterGroup,
  setDashboard_selectedFromSite,
  setDashboard_selectedOuId,
  setDashboard_selectedPermittedCostCenters,
  setDashboard_selectedPermittedToSites,
  setDashboard_selectedPredefinedDateRange,
  setDashboard_selectedProcessState,
  setDashboard_selectedRecipient,
  setDashboard_selectedSettledStatus,
  setDashboard_selectedSupplier,
  setDashboard_selectedToSiteRecipient,
  setDashboard_selectedToSiteSupplier,
  setDashboard_selectedToSiteSupplierTradeContact,
  setDashboard_selectedUnit,
  setDashboard_tab,
  setDataExchanges_query,
  setDataExchanges_selectedCompany,
  setDelivery_dateRange,
  setDelivery_filterGroupOpen,
  setDelivery_filterGroups,
  setDelivery_filterRows,
  setDelivery_individualDateRange,
  setDelivery_predefinedDateRange,
  setDelivery_query,
  setDelivery_selectedAcceptState,
  setDelivery_selectedArticle,
  setDelivery_selectedArticleNumber,
  setDelivery_selectedCategory,
  setDelivery_selectedCostCenter,
  setDelivery_selectedCustomFields,
  setDelivery_selectedFilterGroup,
  setDelivery_selectedFromSite,
  setDelivery_selectedNumber,
  setDelivery_selectedPermittedCostCenters,
  setDelivery_selectedPermittedToSites,
  setDelivery_selectedProcessState,
  setDelivery_selectedRecipient,
  setDelivery_selectedSettledStatus,
  setDelivery_selectedSupplier,
  setDelivery_selectedToSiteRecipient,
  setDelivery_selectedToSiteSupplier,
  setDelivery_selectField,
  setDelivery_selectedOuId,
  setDelivery_tab,
  setDeliveryList_page,
  setDeliveryList_sortModel,
  setFilterValue,
  setHome_individualDateRange,
  setHome_selectedDateRange,
  setHome_selectedPredefinedDateRange,
  setHome_selectedUnit,
  setInvoice_dateRange,
  setInvoice_filterGroupOpen,
  setInvoice_filterGroups,
  setInvoice_filterModel,
  setInvoice_filterRows,
  setInvoice_individualDateRange,
  setInvoice_predefinedDateRange,
  setInvoice_query,
  setInvoice_selectedBuyer,
  setInvoice_selectedFilterGroup,
  setInvoice_selectedNumber,
  setInvoice_selectedSeller,
  setInvoice_selectedStatus,
  setInvoice_selectedToSite,
  setInvoice_selectField,
  setInvoice_sortModel,
  setOldestFilteredDlnDate,
  setOldestFilteredDlnDateLoading,
  setSelectedCostCenters,
  setSelectedSites,
  setSuppliers_responsiblePeople,
  setSuppliers_selectCompany,
  setSuppliers_sortModel,
  // The entire UserMetrics feature can not be implemented with the current paginated API endpoints.
  // Also, nobody uses it.
  // setUserMetrics_dateRange,
  // setUserMetrics_individualDateRange,
  // setUserMetrics_predefinedDateRange,
  // setUserMetrics_sortModel,
} = actions;

export default reducer;
