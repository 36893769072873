import { cn } from '~/utils/tailwind';

import { type IconMetaphor } from './constants';
import { getIcon } from './getIcon';

type P = {
  readonly metaphor: IconMetaphor;
  readonly size?: 'sm' | 'md' | 'lg';
} & ComponentStyling;

export const Icon = ({
  className,
  metaphor,
  size = 'md',
  style,
  ...rest
}: P) => {
  const IconComponent = getIcon(metaphor);

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      className={cn(className, {
        's-4': size === 'sm',
        's-6': size === 'md',
        's-8': size === 'lg',
      })}
      style={style}
      {...rest}
    />
  );
};
