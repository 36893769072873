import { v4 as uuidv4 } from 'uuid';

import { TAB } from '~/constants/Tab';

import { getFilterGroupObject } from './getFilterGroupObject';

export const changesFilterGroup = getFilterGroupObject({
  filterGroupId: TAB.DELIVERY.CHANGES.ID,
  filterGroupName: TAB.DELIVERY.CHANGES.NAME,
  filterGroups: [],
  filterRows: [],
  filterValues: {},
  isEmptyFilterGroup: true,
});

export const unassignedDeliveryNotesFilterGroup = getFilterGroupObject({
  filterGroupId: TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.ID,
  filterGroupName: TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.NAME,
  filterGroups: [],
  filterRows: [],
  filterValues: {},
  isEmptyFilterGroup: true,
});

/**
 * Push the default tabs to the front of the list of filter groups if they are not already present.
 */
export const fixTabsOrder = ({
  defaultTabs,
  dispatch,
  filterGroups,
  setDelivery_filterGroups,
}) => {
  const defaultFilterGroups = defaultTabs.map(({ ID, NAME }) =>
    getFilterGroupObject({
      filterGroupId: ID ?? uuidv4(),
      filterGroupName: NAME,
      filterGroups,
      filterRows: [],
      filterValues: {},
      isEmptyFilterGroup: true,
    }),
  );

  // Replace default filter groups with filter groups from state if they already exist there.
  let newFilterGroups = defaultFilterGroups.map((filterGroup) => {
    const modifiedDefaultFilterGroup = filterGroups.find(
      ({ name }) => name.toLowerCase() === filterGroup.name.toLowerCase(),
    );

    return modifiedDefaultFilterGroup ?? filterGroup;
  });

  // Add remaining filter groups from state that are not based on the default filter groups.
  newFilterGroups = [
    ...newFilterGroups,
    ...filterGroups.filter(({ name }) => {
      const isNotDefaultTab = !defaultTabs.some(({ NAME }) => NAME === name);

      return isNotDefaultTab;
    }),
  ];

  // Replace unassigned delivery notes filter group with the default one, so it's immutable and has a stable id.
  newFilterGroups = newFilterGroups.map((group) => {
    if (group.name === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.NAME) {
      return unassignedDeliveryNotesFilterGroup;
    }

    if (group.name === TAB.DELIVERY.CHANGES.NAME) {
      return changesFilterGroup;
    }

    if (!group.id) {
      return {
        ...group,
        id: uuidv4(),
      };
    }

    return group;
  }); // TODO: this gets overwritten by later redux state updates by saveLoadedUserData

  dispatch(setDelivery_filterGroups(newFilterGroups));
};
