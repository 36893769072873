import Article from '~/models/articles/Article';

export function getArticles(assetMain) {
  if (!assetMain?.assetState?.body?.transaction?.logisticsPackage) {
    return [];
  }

  const { logisticsPackage } = assetMain.assetState.body.transaction;
  const articles = [];

  for (const [packageIndex, package_] of logisticsPackage.entries()) {
    if (!package_?.lineItem) {
      continue;
    }

    for (const [lineItemIndex, lineItem] of package_.lineItem.entries()) {
      if (!lineItem) {
        continue;
      }

      const article = new Article(
        lineItem,
        package_.id,
        packageIndex,
        lineItemIndex,
        assetMain.acceptStates?.acceptItems,
        assetMain.billedItems,
      );

      articles.push(article);
    }
  }

  return articles;
}
