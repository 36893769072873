import { Divider, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TAB } from '~/constants/Tab';

import { ClientPortalTooltip } from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import { PackageBasicRestrictionTooltip } from '~/components/salesPackages/packageBasicRestriction/PackageBasicRestrictionTooltip';
import { withErrorBoundary } from '~/ui/atoms';
import { DateRangeSelect } from '~/ui/molecules/DateInput';
import { Select } from '~/ui/molecules/Select';

import {
  DashboardCharts,
  DashboardFilterGroups,
  DashboardReport,
} from './components';
import { selectableUnits } from './constants';
import { useDashboard } from './useDashboard';

export const Dashboard = withErrorBoundary(() => {
  const { t } = useTranslation();

  const {
    aggregationInterval,
    featureFlags,
    handleChangeTab,
    handleDateRangeChange,
    handleFilterChange,
    handlePredefinedDateRangeChange,
    handleUnitChange,
    individualDateRange,
    searchBody,
    selectedDateRange,
    selectedPredefinedDateRange,
    selectedTab,
    selectedUnit,
  } = useDashboard();

  return (
    <div className="main-padding flex h-full flex-col gap-4">
      <div className="rounded-md bg-white p-4 shadow-lg">
        <h3 className="text-lg font-medium">Filter auswählen</h3>
        <DashboardFilterGroups
          onChangeValue={(id, customField, filterValue) => {
            handleFilterChange(id, customField, filterValue);
          }}
        />
        <Divider sx={{ my: 2 }} />
        <div>
          <h3 className="pb-1 text-lg font-medium">Einheit</h3>
          <Select
            className="w-48"
            options={selectableUnits}
            placeholder={t('dashboard.unitSelect.placeholder')}
            value={selectedUnit ?? ''}
            onChange={handleUnitChange}
          />
        </div>
        <Divider sx={{ my: 2 }} />
        <div>
          <h3 className="pb-1 text-lg font-medium">Zeitraum</h3>
          <PackageBasicRestrictionTooltip>
            <ClientPortalTooltip>
              <DateRangeSelect
                predefinedDateRange={selectedPredefinedDateRange}
                isIndividualDateRange={individualDateRange}
                dateRange={selectedDateRange}
                isDisabled={
                  featureFlags.clientPortal ||
                  featureFlags.packageBasicRestriction
                }
                onPredefinedDateRangeChange={handlePredefinedDateRangeChange}
                onDateRangeChange={handleDateRangeChange}
              />
            </ClientPortalTooltip>
          </PackageBasicRestrictionTooltip>
        </div>
      </div>
      <div className="flex flex-1 flex-col rounded-md bg-white p-4 shadow-lg">
        <Tabs
          value={selectedTab}
          className="border-b border-gray-200"
          onChange={handleChangeTab}
        >
          <Tab
            label={TAB.DASHBOARD.CHARTS.NAME}
            data-testid={TAB.DASHBOARD.CHARTS.TESTID}
          />
          <Tab
            data-testid={TAB.DASHBOARD.REPORT.TESTID}
            label={TAB.DASHBOARD.REPORT.NAME}
            disabled={
              featureFlags.clientPortal || featureFlags.packageBasicRestriction
            }
          />
        </Tabs>
        {selectedTab === TAB.DASHBOARD.CHARTS.INDEX && (
          <DashboardCharts
            aggregationInterval={aggregationInterval}
            searchBody={searchBody}
            selectedDateRange={selectedDateRange}
            selectedUnit={selectedUnit}
          />
        )}
        {selectedTab === TAB.DASHBOARD.REPORT.INDEX && (
          <DashboardReport searchBody={searchBody} />
        )}
      </div>
      <div
        className="min-h-8"
        /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
        /* A better solution would be to make the parent container min-h-fit so that the padding of main-padding is applied. */
        /* However, min-h-fit seems to not work with h-fill or generally with flexbox and flex-1. */
      />
    </div>
  );
}, 'Statistiken konnten nicht geladen werden.');

Dashboard.displayName = 'Dashboard';
