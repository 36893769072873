import { camelize, decamelize, pascalize } from 'fast-case';

/**
 * Convert a string to camelCase.
 */
export const toCamelCase = (variableName: string) =>
  typeof variableName === 'string' ? camelize(variableName) : variableName;

/**
 * Convert a string to PascalCase.
 */
export const toPascalCase = (variableName: string) =>
  typeof variableName === 'string' ? pascalize(variableName) : variableName;

/**
 * Convert a string to snake_case.
 */
export const toSnakeCase = (variableName: string) =>
  typeof variableName === 'string' ? decamelize(variableName) : variableName;

/**
 * Truncates a string to a specified maximum length and appends an ellipsis if truncated.
 *
 * @param {string} value - The string to be truncated.
 * @param {number} [maxLength=26] - The maximum length of the string.
 * @return {string} The truncated string.
 */
export const truncate = (value: string, maxLength = 26) => {
  if (!value) {
    return '';
  }

  return value.length >= maxLength ? `${value.slice(0, maxLength)}…` : value;
};
