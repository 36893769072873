import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import Attachment from '~/models/deliveries/Attachment';
import SignatureRoles from '~/models/masterdata/SignatureRoles';

import { joinComponents } from '~/utils/array';
import Log from '~/utils/logging';
import { toCamelCase } from '~/utils/string';
import UnitUtils from '~/utils/unitUtils';

export default class AcceptArticle {
  constructor(acceptItem, chainId) {
    this.acceptStatus = acceptItem?.accept ?? null;
    this.partialAcceptStatus = acceptItem?.partialAccept ?? null;
    this.reason = acceptItem?.reason ?? null;
    this.attachments =
      acceptItem?.attachments?.map(
        (attachment) =>
          new Attachment(attachment.blob, chainId, attachment.type),
      ) ?? [];
    this.acceptState = this.getAcceptState();
    this.documentLogisticsPackagePosition =
      this.getDocumentLogisticsPackagePosition(acceptItem?.path);
    this.documentLineItemPosition = this.getDocumentLineItemPosition(
      acceptItem?.path,
    );
    // The unit is displayed in the dln history and the article list for the declined amount.
    // It is set via setUnit because at the time of the object initialization it isn't always given (e.g. in DeliveryNoteAction).
    this.unit = null;
  }

  getAcceptState() {
    if (this.acceptStatus) {
      return this.acceptStatus === AcceptArticle.ACCEPT_STATUS.VALID.KEY
        ? AcceptStateCalculator.ACCEPT_STATE.APPROVED
        : AcceptStateCalculator.ACCEPT_STATE.DECLINED;
    }

    if (!this.partialAcceptStatus) {
      return AcceptStateCalculator.ACCEPT_STATE.OPEN;
    }

    return AcceptStateCalculator.ACCEPT_STATE.DECLINED;
  }

  getDocumentLogisticsPackagePosition(path) {
    if (!path) {
      return null;
    }

    const camelCasePath = path.map((item) => toCamelCase(item));
    const index = camelCasePath.indexOf('logisticsPackage');

    return path[index + 1];
  }

  getDocumentLineItemPosition(path) {
    if (!path) {
      return null;
    }

    const camelCasePath = path.map((item) => toCamelCase(item));
    const index = camelCasePath.indexOf('lineItem');

    return path[index + 1];
  }

  // For each partial accept status (e.g. damaged: 4), format the value, the unit and the status into one component (e.g. "4 Stk beschädigt").
  // Join the multiple status with a comma.
  partialAcceptStatusToString() {
    const items = [];

    for (const x of Object.keys(this.partialAcceptStatus)) {
      if (x === AcceptArticle.ACCEPT_STATUS.VALID.KEY) {
        continue;
      }

      if (x === AcceptArticle.ACCEPT_STATUS.DELTA.KEY) {
        if (this.partialAcceptStatus[x] < 0) {
          items.push(
            <>
              {UnitUtils.formatValueUnitPair_safe(
                -this.partialAcceptStatus[x],
                this.unit,
                UnitUtils.getAbbreviatedUnit,
              )}{' '}
              überschüssig
            </>,
          );
        }

        if (this.partialAcceptStatus[x] > 0) {
          items.push(
            <>
              {UnitUtils.formatValueUnitPair_safe(
                this.partialAcceptStatus[x],
                this.unit,
                UnitUtils.getAbbreviatedUnit,
              )}{' '}
              fehlt
            </>,
          );
        }

        continue;
      }

      const status = Object.keys(AcceptArticle.ACCEPT_STATUS).find(
        (y) => x === AcceptArticle.ACCEPT_STATUS[y].KEY,
      );

      if (!status) {
        Log.error(
          null,
          new EnumValueNotFoundException(`Invalid partial accept status: ${x}`),
        );
        continue;
      }

      items.push(
        <>
          {UnitUtils.formatValueUnitPair_safe(
            this.partialAcceptStatus[x],
            this.unit,
            UnitUtils.getAbbreviatedUnit,
          )}{' '}
          {AcceptArticle.ACCEPT_STATUS[status].DESCRIPTION}
        </>,
      );
    }

    return joinComponents(items);
  }

  getDescriptiveAcceptStatus() {
    const acceptStatusConst = Object.keys(AcceptArticle.ACCEPT_STATUS).find(
      (x) => AcceptArticle.ACCEPT_STATUS[x].KEY === this.acceptStatus,
    );

    if (!acceptStatusConst) {
      throw new EnumValueNotFoundException(
        `Invalid accept status: ${this.acceptStatus}`,
      );
    }

    return AcceptArticle.ACCEPT_STATUS[acceptStatusConst].DESCRIPTION;
  }

  setUnit(unit) {
    this.unit = unit;
  }

  // Return the correct accept article for a specific party.
  // Input are the accept articles per party from the backend (e.g. acceptItems: {supplier: [], carrier: [], recipient: []})
  // Thus, create an accept article for each of the accept items of the corresponding parties. Then find the correct accept article by log package and line item position.
  static getAcceptArticleOfParty(
    acceptItems,
    party,
    articleDocumentLogisticsPackagePosition,
    articleDocumentLineItemPosition,
  ) {
    if (party === SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY) {
      return this.getAcceptArticleOfRecipient(
        acceptItems,
        articleDocumentLogisticsPackagePosition,
        articleDocumentLineItemPosition,
      );
    }

    const acceptArticles =
      acceptItems?.[party]?.map(
        (acceptItem) => new AcceptArticle(acceptItem),
      ) ?? [];
    const acceptArticle = acceptArticles.find(
      (acceptArticle) =>
        acceptArticle.documentLogisticsPackagePosition ===
          articleDocumentLogisticsPackagePosition &&
        acceptArticle.documentLineItemPosition ===
          articleDocumentLineItemPosition,
    );

    return acceptArticle ?? new AcceptArticle();
  }

  // Handle the case that for legacy dlns, the accept article of the recipient is written into buyer.
  static getAcceptArticleOfRecipient(
    acceptItems,
    articleDocumentLogisticsPackagePosition,
    articleDocumentLineItemPosition,
  ) {
    const acceptArticlesRecipient =
      acceptItems?.[SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY]?.map(
        (acceptItem) => new AcceptArticle(acceptItem),
      ) ?? [];
    const acceptArticlesLegacyRecipient =
      acceptItems?.[SignatureRoles.SIGNATURE_ROLE.RECIPIENT.LEGACY_KEY]?.map(
        (acceptItem) => new AcceptArticle(acceptItem),
      ) ?? [];

    const acceptStateRecipient =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        acceptArticlesRecipient.map(
          (acceptArticle) => acceptArticle.acceptState,
        ),
      );
    const acceptStateLegacyRecipient =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        acceptArticlesLegacyRecipient.map(
          (acceptArticle) => acceptArticle.acceptState,
        ),
      );

    let acceptArticles = [];

    // If the accept state of recipient is open and the accept state of buyer isn't open,
    // we assume that this is a legacy dln where the recipient accept article has been written into buyer.
    // However, this assumption isn't 100% safe and can lead to wrong results, but it is currently the best approach to handle legacy dlns.
    if (
      acceptStateLegacyRecipient !== AcceptStateCalculator.ACCEPT_STATE.OPEN &&
      acceptStateRecipient === AcceptStateCalculator.ACCEPT_STATE.OPEN
    ) {
      acceptArticles = acceptArticlesLegacyRecipient;
    } else {
      acceptArticles = acceptArticlesRecipient;
    }

    const acceptArticle = acceptArticles.find(
      (acceptArticle) =>
        acceptArticle.documentLogisticsPackagePosition ===
          articleDocumentLogisticsPackagePosition &&
        acceptArticle.documentLineItemPosition ===
          articleDocumentLineItemPosition,
    );

    return acceptArticle ?? new AcceptArticle();
  }

  static ACCEPT_STATUS = {
    DAMAGED: {
      DESCRIPTION: 'beschädigt',
      KEY: 'damaged',
    },
    DECLINED: {
      DESCRIPTION: 'storniert',
      KEY: 'declined',
    },
    DELTA: {
      DESCRIPTION: 'überschüssig/fehlt',
      KEY: 'delta',
    },
    VALID: {
      DESCRIPTION: 'erhalten',
      KEY: 'valid',
    },
    WRONG: {
      DESCRIPTION: 'falsch',
      KEY: 'wrong',
    },
  };
}
