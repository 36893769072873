import { lazy, Suspense } from 'react';
import { type RouteObject } from 'react-router';

import { ROUTE } from '~/constants/Route';

import { sentryCreateBrowserRouter } from '~/services/thirdParty.service';

import { AppInitializer } from '~/modules/appInitializer';
import { LoadingPage, RootErrorBoundary } from '~/modules/router/components';

import { ProtectedRoute } from './ProtectedRoute';

const ArticlesTable = lazy(async () =>
  import('~/components/settings/admin/articleMaster/ArticlesTable').then(
    ({ ArticlesTable }) => ({
      default: ArticlesTable,
    }),
  ),
);

const ProfileSettings = lazy(async () =>
  import('~/components/settings/profile/ProfileSettings').then(
    ({ ProfileSettings }) => ({
      default: ProfileSettings,
    }),
  ),
);

const CompaniesTable = lazy(async () =>
  import('~/components/settings/masterData/company/CompaniesTable').then(
    ({ CompaniesTable }) => ({
      default: CompaniesTable,
    }),
  ),
);

const MasterData = lazy(async () =>
  import('~/components/settings/masterData/MasterData').then(
    ({ MasterData }) => ({
      default: MasterData,
    }),
  ),
);

const UsersTable = lazy(async () =>
  import('~/components/settings/masterData/users/UsersTable').then(
    ({ UsersTable }) => ({
      default: UsersTable,
    }),
  ),
);

const SitesTable = lazy(async () =>
  import('~/components/settings/masterData/site/SitesTable').then(
    ({ SitesTable }) => ({
      default: SitesTable,
    }),
  ),
);

const CostCentersTable = lazy(async () =>
  import('~/components/settings/masterData/costCenter/CostCentersTable').then(
    ({ CostCentersTable }) => ({
      default: CostCentersTable,
    }),
  ),
);

const VehiclesTable = lazy(async () =>
  import('~/components/settings/masterData/vehicle/VehiclesTable').then(
    ({ VehiclesTable }) => ({
      default: VehiclesTable,
    }),
  ),
);

const OrganizationalUnitsTable = lazy(async () =>
  import(
    '~/components/settings/masterData/organizationalUnit/OrganizationalUnitsTable'
  ).then(({ OrganizationalUnitsTable }) => ({
    default: OrganizationalUnitsTable,
  })),
);

const UserGroupsTable = lazy(async () =>
  import('~/components/settings/masterData/userGroup/UserGroupsTable').then(
    ({ UserGroupsTable }) => ({
      default: UserGroupsTable,
    }),
  ),
);

const CustomFieldsTable = lazy(async () =>
  import('~/components/settings/admin/customField/CustomFieldsTable').then(
    ({ CustomFieldsTable }) => ({
      default: CustomFieldsTable,
    }),
  ),
);

const SignatureFieldsTable = lazy(async () =>
  import(
    '~/components/settings/admin/signatureField/SignatureFieldsTable'
  ).then(({ SignatureFieldsTable }) => ({
    default: SignatureFieldsTable,
  })),
);

const CategoriesTable = lazy(async () =>
  import('~/components/settings/admin/articleMaster/CategoriesTable').then(
    ({ CategoriesTable }) => ({
      default: CategoriesTable,
    }),
  ),
);

const CustomerSubscriptionsTable = lazy(async () =>
  import(
    '~/components/settings/admin/customerSubscription/CustomerSubscriptionsTable'
  ).then(({ CustomerSubscriptionsTable }) => ({
    default: CustomerSubscriptionsTable,
  })),
);

const InvoiceCheckIgnoredArticlesTable = lazy(async () =>
  import(
    '~/components/settings/admin/invoiceCheckIgnoredArticle/InvoiceCheckIgnoredArticlesTable'
  ).then(({ InvoiceCheckIgnoredArticlesTable }) => ({
    default: InvoiceCheckIgnoredArticlesTable,
  })),
);

const Demo = lazy(async () =>
  import('~/components/settings/Demo').then(({ Demo }) => ({
    default: Demo,
  })),
);

const DataProtection = lazy(async () =>
  import('~/components/settings/dataProtection/DataProtection').then(
    ({ DataProtection }) => ({
      default: DataProtection,
    }),
  ),
);

const Impressum = lazy(async () =>
  import('~/components/settings/Impressum').then(({ Impressum }) => ({
    default: Impressum,
  })),
);

const PdfSettings = lazy(
  async () => import('~/components/settings/admin/pdf/PdfSettings'),
);

const DataSubscriptions = lazy(
  async () =>
    import('~/components/settings/admin/dataSubscriptions/DataSubscriptions'),
);

const SupportSettings = lazy(
  async () => import('~/components/settings/support/SupportSettings'),
);

const Home = lazy(async () =>
  import('~/modules/home').then(({ Home }) => ({ default: Home })),
);

const Dashboard = lazy(async () =>
  import('~/modules/dashboard').then(({ Dashboard }) => ({
    default: Dashboard,
  })),
);

const DeliveryOverview = lazy(async () =>
  import('../deliveries/deliveryOverview').then(({ DeliveryOverview }) => ({
    default: DeliveryOverview,
  })),
);

const Settings = lazy(async () =>
  import('../settings/Settings').then(({ Settings }) => ({
    default: Settings,
  })),
);

const DataExchangePage = lazy(
  async () => import('../dataExchanges/DataExchangePage'),
);

const DeliveryNoteForm = lazy(
  async () => import('../deliveries/deliveryNoteForm/DeliveryNoteForm'),
);

const ServiceNoteForm = lazy(
  async () => import('../deliveries/serviceNoteForm/ServiceNoteForm'),
);

const DeliveryNote = lazy(async () =>
  import('../deliveries/deliveryNote/DeliveryNote').then(
    ({ DeliveryNote }) => ({
      default: DeliveryNote,
    }),
  ),
);

const DeliveryNoteDataQuality = lazy(async () =>
  import('../deliveries/deliveryNoteDataQuality/DeliveryNoteDataQuality').then(
    ({ DeliveryNoteDataQuality }) => ({
      default: DeliveryNoteDataQuality,
    }),
  ),
);

const Invoice = lazy(async () =>
  import('~/modules/invoice/detail').then(({ Invoice }) => ({
    default: Invoice,
  })),
);

const InvoiceOverview = lazy(async () =>
  import('~/modules/invoice/list').then(({ InvoiceOverview }) => ({
    default: InvoiceOverview,
  })),
);

export const ROUTES: RouteObject[] = [
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.HOME.ROUTE,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.DASHBOARD.ROUTE,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <DeliveryOverview />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.DELIVERIES.ROUTE,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <DeliveryNote />
        </ProtectedRoute>
      </Suspense>
    ),
    path: `${ROUTE.DELIVERY_NOTE.ROUTE}/:id`,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <DeliveryNoteForm />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <ServiceNoteForm />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <DeliveryNoteDataQuality />
        </ProtectedRoute>
      </Suspense>
    ),
    path: `${ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE}/:id`,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <InvoiceOverview />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.OUTGOING_INVOICES.ROUTE,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <InvoiceOverview />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.INCOMING_INVOICES.ROUTE,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <Invoice />
        </ProtectedRoute>
      </Suspense>
    ),
    path: `${ROUTE.OUTGOING_INVOICE.ROUTE}/:id`,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <Invoice />
        </ProtectedRoute>
      </Suspense>
    ),
    path: `${ROUTE.INCOMING_INVOICE.ROUTE}/:id`,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <Invoice />
        </ProtectedRoute>
      </Suspense>
    ),
    path: `${ROUTE.INVOICE.ROUTE}/:id`,
  },
  {
    children: [
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <ProfileSettings />
          </Suspense>
        ),
        path: ROUTE.SETTINGS.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <ProfileSettings />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_PROFILE.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <SupportSettings />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_SUPPORT.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <CompaniesTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_COMPANY.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <MasterData />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_MASTERDATA.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <UsersTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_USER.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <SitesTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_SITE.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <CostCentersTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_COST_CENTER.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <VehiclesTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_VEHICLE.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <OrganizationalUnitsTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_ORGANISATIONAL_GROUP.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <UserGroupsTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_USER_GROUP.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <DataSubscriptions />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_DATA_SUBSCRIPTIONS.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <CustomFieldsTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_CUSTOM_FIELDS.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <SignatureFieldsTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_SIGNATURE_FIELDS.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <ArticlesTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_ARTICLES.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <CategoriesTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_CATEGORIES.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <CustomerSubscriptionsTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_CUSTOMER_SUBSCRIPTIONS.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <PdfSettings />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_PDF.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <InvoiceCheckIgnoredArticlesTable />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_INVOICE_CHECK_IGNORED_ARTICLES.ROUTE,
      },
      { element: <Demo />, path: ROUTE.SETTINGS_DEMO.ROUTE },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <DataProtection />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_DATA_PROTECTION.ROUTE,
      },
      {
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Impressum />
          </Suspense>
        ),
        path: ROUTE.SETTINGS_IMPRESSUM.ROUTE,
      },
    ],
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute loadingTitle="Lade Einstellungen...">
          <Settings />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.SETTINGS.ROUTE,
  },
  {
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ProtectedRoute>
          <DataExchangePage />
        </ProtectedRoute>
      </Suspense>
    ),
    path: ROUTE.DATA_EXCHANGE.ROUTE,
  },
];

export const router = sentryCreateBrowserRouter(
  [
    {
      children: ROUTES,
      element: <AppInitializer />,
      errorElement: <RootErrorBoundary />,
    },
  ],
  {
    future: {
      unstable_skipActionErrorRevalidation: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_startTransition: true,
    },
  },
);
