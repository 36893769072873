import { useDispatch } from 'react-redux';

import { LOADING_STATE } from '~/constants/LoadingState';

import {
  saveOutgoingInvoices,
  setOutgoingInvoicesLoading,
} from '~/redux/invoicesSlice';

import InvoicesService from '~/services/invoices.service';

import Log from '~/utils/logging';

/**
 * Custom hook for loading outgoing invoices.
 *
 * This hook provides functionality to load outgoing invoices either from
 * the window.redux_state (if available and successfully loaded) or by fetching
 * from the backend using InvoicesService.
 *
 * @returns {Object} An object containing the loadOutgoingInvoices function.
 */
export const useLoadOutgoingInvoices = () => {
  const dispatch = useDispatch();

  /**
   * Loads outgoing invoices.
   *
   * If invoices are available in window.redux_state and successfully loaded,
   * it dispatches actions to update the Redux store with the loaded data.
   * Otherwise, it calls InvoicesService.getAllOutgoingInvoices() to fetch the data.
   */
  async function loadOutgoingInvoices() {
    if (
      /**
       * Opening an item in a new browser tab using BrowserUtils.openMenuItemInNewTab or BrowserUtils.openNewTab
       * passes the whole Redux state to the new tab as window.redux_state.
       * If that is the case, we don't need to load the data again from the backend,
       * but push the data from the window object into Redux state.
       */
      globalThis.redux_state &&
      globalThis.redux_state.invoices.outgoingInvoicesLoading ===
        LOADING_STATE.SUCCEEDED
    ) {
      dispatch(
        saveOutgoingInvoices(globalThis.redux_state.invoices.outgoingInvoices),
      );

      Log.info('Invoice state passed via window.redux_state.');

      return;
    }

    dispatch(setOutgoingInvoicesLoading(LOADING_STATE.LOADING));

    // Load data of outgoing invoices from the backend.
    InvoicesService.getAllOutgoingInvoices().catch((error) => {
      dispatch(setOutgoingInvoicesLoading(LOADING_STATE.FAILED));
    });
  }

  return {
    loadOutgoingInvoices,
  };
};
