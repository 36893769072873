/**
 * Interface for `react-hotkeys-hook` library for defining keyboard actions in React.
 *
 * @see https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/basic-usage
 * @see https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/disable-hotkeys
 * @see https://react-hotkeys-hook.vercel.app/docs/documentation/hotkeys-provider
 */
import { type Hotkey } from 'react-hotkeys-hook/dist/types';

import { type UniqueArray } from './lang';
import { getOperatingSystem } from './operatingSystem';

export * from 'react-hotkeys-hook';
export type * from 'react-hotkeys-hook/dist/types';

export type SpecialKey = 'meta' | 'ctrl' | 'alt' | 'shift' | 'mod' | 'os';

/**
 * Extracts the special modifier keys (meta, ctrl, alt, shift, mod) from a hotkey configuration.
 *
 * @param hotkey - The hotkey configuration object from react-hotkeys-hook
 * @returns An array of active special keys in the hotkey combination
 * @example
 * // For a hotkey like "cmd+shift+p", returns ['meta', 'shift']
 * specialKeysOfHotkey({ meta: true, shift: true, key: 'p' })
 */
export const specialKeysOfHotkey = (
  hotkey: Hotkey,
): UniqueArray<SpecialKey> => {
  const specialKeys: SpecialKey[] = [];

  for (const key of [
    'meta',
    'ctrl',
    'alt',
    'shift',
    'mod',
    'os',
  ] as UniqueArray<SpecialKey>) {
    if (hotkey[key]) {
      specialKeys.push(key);
    }
  }

  return specialKeys as UniqueArray<SpecialKey>;
};

/**
 * Interface for `ts-key-enum`, a TypeScript string enum for compile-time safety when working with event.key.
 *
 * @see https://gitlab.com/nfriend/ts-key-enum#readme
 */
export { Key } from 'ts-key-enum';

/**
 * Maps a special key character to its corresponding character based on the current operating system.
 *
 * @param key - The key to map.
 * @returns The mapped key character.
 */
export const mapSpecialKeyCharacter = (key: string) => {
  // meta is the same as cmd on macOS and os key on Windows
  if (key === 'meta') {
    switch (getOperatingSystem()) {
      case 'macOS': {
        return '⌘';
      }

      case 'Windows': {
        return '⊞';
      }

      case 'Linux': {
        return 'Ctrl';
      }

      default: {
        return 'Ctrl';
      }
    }
  }

  // mod listens for ctrl on Windows/Linux and cmd on macOS
  if (key === 'mod') {
    switch (getOperatingSystem()) {
      case 'macOS': {
        return '⌘';
      }

      case 'Windows': {
        return 'Ctrl';
      }

      case 'Linux': {
        return 'Ctrl';
      }

      default: {
        return 'Ctrl';
      }
    }
  }

  if (key === 'os') {
    return '⊞';
  }

  if (key === 'enter') {
    return '⏎';
  }

  if (key === 'shift') {
    return '⇧';
  }

  if (key === 'tab') {
    return '⇥';
  }

  if (key === 'delete') {
    return '⌫';
  }

  if (key === 'up') {
    return '↑';
  }

  if (key === 'right') {
    return '→';
  }

  if (key === 'down') {
    return '↓';
  }

  if (key === 'left') {
    return '←';
  }

  return key;
};
