import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { memo, useEffect, useMemo, useRef } from 'react';

import { useGetFilterConfig } from '~/data/deliveryNote';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { sortByKey } from '~/utils/array';
import { isCustomFieldName } from '~/utils/customField';
import { type FilterContext, getFilterContext } from '~/utils/filters';
import { toCamelCase } from '~/utils/string';

import { SearchInput } from '~/ui/molecules/SearchInput';
import { Select } from '~/ui/molecules/Select';
import {
  MultiSelectCostCenters,
  MultiSelectCustomFieldValues,
  MultiSelectFilterValues,
  MultiSelectOrganizationalUnits,
  MultiSelectSites,
} from '~/ui/molecules/SelectServerDriven';

type Filter = {
  allOptions: string[];
  disabled: boolean;
  filteredOptions: string[];
  id: string;
  name: string;
};

type P = {
  readonly filterContext: FilterContext;
  readonly hideDeleteButton: boolean;
  readonly isDisabled: boolean;
  readonly onChangeProperty: (event) => void;
  readonly onChangeValue: (event, value) => void;
  readonly onDeleteRow: () => void;
  readonly selectableFilters: Filter[];
  readonly selectedFilter: Filter;
  readonly selectedValues: string[] | boolean;
  readonly testId?: string;
};

export const FilterRow = memo(
  ({
    filterContext = getFilterContext(),
    hideDeleteButton,
    isDisabled,
    onChangeProperty,
    onChangeValue,
    onDeleteRow,
    selectableFilters,
    selectedFilter,
    selectedValues,
    testId,
  }: P) => {
    const filterConfig = useGetFilterConfig({ filterContext });

    const filterOptions = sortByKey(
      selectableFilters.map(({ disabled, id, name }) => ({
        data: {
          disabled,
        },
        label: name,
        value: id,
      })) ?? [],
      'label',
    );

    const filterName = selectedFilter?.id?.replace(/^selected/, '');
    const normalizedFilterName = toCamelCase(filterName) ?? '';

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }, [selectedFilter.id]);

    const InputComponent = useMemo(
      () => () => {
        if (
          ['acceptState', 'category', 'processState', 'settledStatus'].includes(
            normalizedFilterName,
          )
        ) {
          let options: string[] = [];
          switch (normalizedFilterName) {
            case 'acceptState': {
              options = AcceptStateCalculator.getAcceptStates();
              break;
            }

            case 'processState': {
              options = DeliveryNote.getProcessStateOptions();
              break;
            }

            case 'settledStatus': {
              options = ['Ja', 'Nein'];
              break;
            }

            case 'category': {
              options = ['Eingehend', 'Ausgehend', 'Intern'];
              break;
            }
          }

          return (
            <ToggleButtonGroup
              value={selectedValues}
              className="bg-white"
              size="small"
              data-testid={`${testId}_filter_operator_value_select`}
              onChange={(event, value) => {
                onChangeValue(event, value);
              }}
            >
              {options.map((option) => (
                <ToggleButton
                  key={option}
                  value={option}
                  className="min-w-16 px-4"
                >
                  {option}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          );
        }

        if (normalizedFilterName === 'permittedToSites') {
          return (
            <MultiSelectSites
              key={filterName}
              hasEmptyOption
              inputRef={inputRef}
              data-testid={`${testId}_filter_operator_value_select`}
              className="min-w-96"
              placeholder="Lieferorte auswählen"
              value={selectedValues}
              onChange={(values) => {
                onChangeValue(
                  {},
                  values.map(({ id }) => id),
                );
              }}
            />
          );
        }

        if (normalizedFilterName === 'permittedCostCenters') {
          return (
            <MultiSelectCostCenters
              key={filterName}
              hasEmptyOption
              inputRef={inputRef}
              data-testid={`${testId}_filter_operator_value_select`}
              className="min-w-96"
              placeholder="Kostenstellen auswählen"
              value={selectedValues}
              onChange={(values) => {
                onChangeValue(
                  {},
                  values.map(({ id }) => id),
                );
              }}
            />
          );
        }

        if (normalizedFilterName === 'ouId') {
          return (
            <MultiSelectOrganizationalUnits
              key={filterName}
              inputRef={inputRef}
              value={selectedValues}
              className="min-w-96"
              placeholder="Organisations-Gruppen auswählen"
              onChange={(values) => {
                onChangeValue(
                  {},
                  values.map(({ id }) => id),
                );
              }}
            />
          );
        }

        if (isCustomFieldName(filterName)) {
          const hasPredefinedOptions = Boolean(
            selectableFilters.find(({ id }) => id === filterName)?.type ===
              'enumeration',
          );

          if (hasPredefinedOptions) {
            return (
              <MultiSelectCustomFieldValues
                key={filterName}
                value={selectedValues}
                className="min-w-96"
                customFieldId={filterName}
                placeholder="Werte auswählen"
                onChange={(values) => {
                  onChangeValue(
                    {},
                    values.map(({ id }) => id),
                  );
                }}
              />
            );
          }

          return (
            <SearchInput
              ref={inputRef}
              value={selectedValues.join('; ') ?? ''}
              debounceTime={800}
              placeholder="Werte eingeben, getrennt durch Strichpunkte"
              testId={`${testId}_filter_operator_value_select`}
              startAdornment={null}
              className="w-96"
              onChange={(value) => {
                onChangeValue(
                  {},
                  value.split(';').map((v) => v.trim()),
                );
              }}
            />
          );
        }

        return (
          <MultiSelectFilterValues
            key={filterName}
            hasEmptyOption
            inputRef={inputRef}
            className="min-w-96"
            data-testid={`${testId}_filter_operator_value_select`}
            filterConfig={filterConfig}
            filterName={filterName}
            isDisabled={isDisabled}
            placeholder="Wert auswählen"
            value={selectedValues}
            onChange={(values) => {
              onChangeValue({}, values);
            }}
          />
        );
      },
      [normalizedFilterName, onChangeValue, selectedValues, testId],
    );

    return (
      <div className="flex gap-2" data-testid={testId}>
        <div className="min-w-80">
          <Select
            testId={`${testId}_operator_select`}
            value={selectedFilter.id}
            options={filterOptions}
            placeholder="Filter auswählen"
            isClearable={false}
            onChange={onChangeProperty}
          />
        </div>
        <InputComponent />
        {hideDeleteButton ? null : (
          <IconButton
            size="small"
            data-testid={`${testId}_delete_button`}
            onClick={onDeleteRow}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  },
);

FilterRow.displayName = 'FilterRow';
