import CustomField from '~/models/customData/CustomField';

import { unique } from '~/utils/array';

export const getCustomFieldIdsFromCustomData = (customData) => {
  if (!customData) {
    return [];
  }

  const customFieldIds = [];

  for (const entry of customData.getEntries()) {
    const customDataKey = entry[0];
    const customFieldId = CustomField.getIdFromKey(customDataKey);

    if (!customFieldId) {
      continue;
    }

    customFieldIds.push(customFieldId);
  }

  return customFieldIds;
};

export const getCustomFieldIdsFromArticles = (articles) => {
  if (!articles?.length) {
    return [];
  }

  const customFieldIds = [];

  for (const article of articles) {
    const articleCustomData = article.customData;

    for (const articleEntry of articleCustomData.getEntries()) {
      const customDataKey = articleEntry[0];
      const customFieldId = CustomField.getIdFromKey(customDataKey);

      if (!customFieldId) {
        continue;
      }

      customFieldIds.push(customFieldId);
    }
  }

  return customFieldIds;
};

export const getCustomFieldIds = (articles, customData) => {
  const customDataCustomFieldIds = getCustomFieldIdsFromCustomData(customData);
  const articleCustomFieldIds = getCustomFieldIdsFromArticles(articles);

  return unique([...customDataCustomFieldIds, ...articleCustomFieldIds]);
};
