import clsx from 'clsx';
import { type PropsWithChildren } from 'react';

/**
 * Renders a component that visually resembles the keyboard key it represents.
 *
 * @param key - The key to be rendered.
 * @return The keyboard key component.
 */
export const KeyboardKey = ({
  children,
  className,
  style,
}: PropsWithChildren & ComponentStyling) => (
  <span
    className={clsx(
      'border-tiga-text-default rounded-md border px-2 py-0.5 uppercase',
      className,
    )}
    style={style}
  >
    {children}
  </span>
);
