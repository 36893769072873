import { pipe } from '~/utils/array';
import { dayjs } from '~/utils/datetime';

import {
  DISPLAY_IF_YOUNGER_THAN_HOURS,
  PICKUP_NUMBER_KEY,
  PICKUP_NUMBER_KEY_FALLBACK,
} from './constants';

type DeliveryNote = Record<string, string>;

const getPickupNumberText = (deliveryNote: DeliveryNote) => {
  for (const key in deliveryNote) {
    if (key.startsWith(PICKUP_NUMBER_KEY) && deliveryNote[key]) {
      console.info(
        `- deliveryNote ${deliveryNote[key]} (number ${deliveryNote[PICKUP_NUMBER_KEY_FALLBACK]})`,
        {
          modifiedOn: deliveryNote.modifiedOn,
        },
      );
      return deliveryNote[key];
    }
  }

  if (deliveryNote?.[PICKUP_NUMBER_KEY_FALLBACK]) {
    // If there is no pickup number, but we have a fallback key, return last 3 digits of fallback key.
    console.info(
      `- deliveryNote w/o custom pickup number (number ${deliveryNote[PICKUP_NUMBER_KEY_FALLBACK]})`,
      {
        modifiedOn: deliveryNote.modifiedOn,
      },
    );
    return `L${deliveryNote[PICKUP_NUMBER_KEY_FALLBACK].slice(-3)}`;
  }

  console.info(`- deliveryNote w/o custom pickup number (w/o number)`, {
    modifiedOn: deliveryNote.modifiedOn,
    deliveryNote,
  });
  return 'n/a';
};

/**
 * Checks if a given modified date is recent by comparing it against a cutoff time.
 *
 * @param {string} modifiedOn - The modified date in ISO format.
 * @return {boolean} Returns true if the modified date is recent, false otherwise.
 */
const isRecent = (modifiedOn: string) => {
  const currentDate = dayjs();
  const parsedDate = dayjs(modifiedOn);

  return parsedDate.isAfter(
    currentDate.subtract(DISPLAY_IF_YOUNGER_THAN_HOURS, 'hours'),
  );
};

const matchesProcessState =
  (targetState: string) =>
  ({ processState: state }: DeliveryNote) =>
    state === targetState;

const sortNumbersDescending = (a: string, b: string) => Number(b) - Number(a);

export const dlnsForProcessState = (
  deliveryRows: DeliveryNote[],
  processState: string,
) => {
  if (!Array.isArray(deliveryRows)) {
    return [];
  }

  console.log(`${processState}:`);

  return pipe(
    deliveryRows,
    (rows) => rows.filter(matchesProcessState(processState)),
    (rows) => rows.filter(({ modifiedOn }) => isRecent(modifiedOn ?? '')),
    (rows) => rows.map((deliveryNote) => getPickupNumberText(deliveryNote)),
    (numbers) => numbers.sort(sortNumbersDescending),
  );
};
