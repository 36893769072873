import { Logout as LogoutIcon } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { useAuth } from 'oidc-react';

import { redirectUrl } from '~/constants/environment';

import Log from '~/utils/logging';

import DataExchanges from './DataExchanges';

export const ExternalSupplierOverview = (props) => {
  const auth = useAuth();

  const logout = () => {
    Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);
    auth.signOutRedirect({
      post_logout_redirect_uri: redirectUrl,
    });
  };

  return (
    <div className="flex h-full min-h-dvh flex-col pt-16">
      <AppBar
        position="fixed"
        className="w-full bg-white pr-0 text-gray-800 transition-all duration-1000 ease-in-out"
      >
        <Toolbar>
          <div className="flex w-full items-center justify-between">
            <span className="text-4xl">Lieferantenübersicht</span>
            <IconButton size="large" onClick={logout}>
              <LogoutIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className="relative flex-1">
        <DataExchanges externalSupplierOverview />
      </div>
    </div>
  );
};
